import { makeVar } from "@apollo/client";
import { Department, Title } from "./useEmployeeList";
import React from "react";
import useExcerpt from "./useExcerpt";
import { newDate } from "../utils/useDuration";

const dataRowInit = {
  name: "Watson Mtonga",
  charge: 50,
  start: "2023-01-01",
  end: "2023-01-01",
  days: 1,
  over: 0,
  advance: 0,
  deduct: 0,
};

export const excerptInit = {
  dataSheetKeys: ["name", "charge", "start", "end", "days", "over", "advance", "deduct"] as const,
  dataRows: [dataRowInit],
  tableKeys: [
    "sn.",
    "name",
    "Day Salary",
    "start date",
    "end date",
    "Working Days",
    "Overwork",
    "Total Amount (ZMW)",
    "Advanced Payment",
    "Compensation",
    "Actual Amount (ZMW)",
    "Confirmed by Signature",
  ] as const,
};

const varExcerpt = makeVar(excerptInit);
const varEdited = makeVar(false);

const genTable = (excerpt?: typeof excerptInit) => {
  if (!excerpt) return [];
  return excerpt.dataRows.map((data) => {
    const overwork = (data.charge / 8) * data.over;
    const total = data.charge * data.days + overwork;
    return {
      "sn.": 0,
      name: data.name,
      "Day Salary": data.charge,
      "start date": newDate(data.start, "dd/MM/yyyy"),
      "end date": newDate(data.end, "dd/MM/yyyy"),
      "Working Days": data.days,
      Overwork: overwork,
      "Total Amount (ZMW)": total,
      "Advanced Payment": data.advance,
      Compensation: data.deduct,
      "Actual Amount (ZMW)": total + data.advance + data.deduct,
      "Confirmed by Signature": "",
    };
  });
};

const useLabor = (slug: string) => {
  const props = useExcerpt(varExcerpt, varEdited, `labor-${slug}`);
  const tableRows = React.useMemo(() => genTable(props.excerpt), [props]);
  const summary = Object.fromEntries(
    excerptInit.tableKeys.map((k) => [
      k,
      k === "name"
        ? "Summary"
        : k !== "sn." && k !== "Confirmed by Signature" && k !== "start date" && k !== "end date"
        ? tableRows.reduce((p, c) => p + c[k], 0)
        : "",
    ])
  );
  return { ...props, tableRows, excerptInit, dataRowInit, summary };
};

export default useLabor;
