import React from "react";
import { Helmet } from "react-helmet";
import { Fab, Stack, StackProps } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

type Props = Omit<StackProps, "title"> & { title?: null | string };

const Layout = ({ title, children, ...ps }: Props) => {
  const navigate = useNavigate();
  return (
    <Stack height="100vh" {...ps}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
      {/* <div style={{ position: "fixed", right: "8px", bottom: "8px", zIndex: 9999, opacity: 0.5 }}>
        <Fab size="small" color="secondary" onClick={() => navigate("/index")}>
          <Menu />
        </Fab>
      </div> */}
    </Stack>
  );
};

export default Layout;
