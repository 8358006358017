import React from "react";
import { ApiDocsQueryVariables, PostStatusEnum } from "../../api/types/graphql";
import useApiDocs from "../useApiDocs";
import { Doc, docParse, docCreateInput, docUpdateInput, docInit } from "./constant-doc";
import { WatchQueryFetchPolicy } from "@apollo/client";

export const useDocs = (where: ApiDocsQueryVariables = {}, fetchPolicy?: WatchQueryFetchPolicy) => {
  const stati = [PostStatusEnum.Draft, PostStatusEnum.Private];
  const s = where.s && { stati, ...where.s };
  const m = where.m && { stati, ...where.m };
  const f = where.f && { stati, ...where.f };
  const { apiDocs, append: _a, update: _u, remove } = useApiDocs({ s, m, f }, fetchPolicy);
  const docs = {
    s: apiDocs.s.map((ad) => docParse<"s">(ad)),
    m: apiDocs.m.map((ad) => docParse<"m">(ad)),
    f: apiDocs.f.map((ad) => docParse<"f">(ad)),
  };
  const append = (doc: Partial<Doc<"f">>) => _a(docCreateInput(doc));
  const update = (doc: Partial<Doc<"f">> & { id: string }) => _u(docUpdateInput(doc));

  return { docs, append, update, remove };
};

export default useDocs;
