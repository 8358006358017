import jsonParse from "../../utils/jsonParse";

export type Currency = (typeof CURRENCIES)[number];
export const CURRENCIES = ["ZMK", "USD", "CNY"] as const;

export type Lang = (typeof LANGS)[number];
export const LANGS = ["ZH", "EN"] as const;

export type Fuel = (typeof FUELS)[number];
export const FUELS = ["UNCLEAR", "PETROL", "DIESEL", "ELECTRIC", "MIXED", "OTHER"] as const;

export type Drive = (typeof DRIVES)[number];
export const DRIVES = ["4WD", "2WD/F", "2WD/R", "UNCLEAR"];

export type Owner = typeof ownerInit;
export const ownerInit = {
  vip: "",
  owner: "",
  tel: "",
};

export type vehicle = typeof vehicleInit;
export const vehicleInit = {
  ...ownerInit,
  plate: "",
  model: "",
  made: "",
  mileage: 0,
  chassis: "",
  engine: "",
  tire: "",
  capacity: 0,
  fuel: "" as Fuel,
  drive: "" as Drive,
};

export type Mat = typeof matInit;
export const matInit = {
  code: "",
  label: "",
  description: "",
  qty: 1,
  cost: 0,
  rate: 0,
};

export type Job = typeof jobInit;
export const jobInit = {
  code: "",
  label: "",
  cost: 0,
  follow: false,
  mats: [] as Mat[],
};

export type Excerpt = typeof excerptInit;

const { vip, plate, model, ...vehicleInExcerpt } = vehicleInit;
export const excerptInit = {
  ...vehicleInExcerpt,
  // ...ownerInit,
  note: "",
  requests: [] as string[],
  jobs: [] as Job[],
  notice: "",
  tax: 0,
  dis: null as number | null,
  lang: "ZH" as Lang,
  showHeader: false,
  showDis: false,
  showTax: false,
  heading: "",
  exchange: { ZMK: 1, USD: 15, CNY: 2.5 } as { [k in Currency]: number },
  currency: "ZMK" as Currency,
};

export const excerptParse = (excerptString?: string | null): Excerpt => {
  const excerpt = jsonParse(excerptString);
  let ex2 = {} as Excerpt;
  Object.entries(excerptInit).map(([_k, v]) => {
    ex2 = { ...ex2, [_k]: (excerpt && excerpt[_k]) || v };
  });
  return ex2;
};

export const excerptSerialize = (excerpt: Partial<Excerpt>): string => {
  return JSON.stringify(excerpt);
};
