import React from "react";

import useDuration, { newDate } from "../utils/useDuration";
import { formatModel, formatPlate } from "../utils/format";
import { WatchQueryFetchPolicy } from "@apollo/client";

import { jobsSummary } from "../utils/jobsSummary";
import { Team, teams, types } from "./docs/constant-title";
import { DocSize, DocWhere } from "./useApiDocs";
import { Doc } from "./docs/constant-doc";
import useDocs from "./docs/useDocs";
import useApiMaxSlug from "./useApiMaxSlug";
import { slugParse } from "./docs/constant-slug";
import { ApiDocsQueryVariables } from "../api/types/graphql";
import { deduplicateObj } from "../utils/deduplicate";

export type Project<Size extends DocSize> = {
  id: number;
  sn: number;
  date: string;
  plate: string;
  model: string;
  vip: string;
  total: number;
  team: Team;
  docsArr: Doc<Size>[][];
};
export const projectInit: Project<"s"> = {
  id: 0,
  sn: 0,
  date: "",
  plate: "",
  model: "",
  vip: "",
  total: 0,
  team: "UNCLEAR",
  docsArr: [[], [], [], [], [], [], []],
};

const getTotal = (doc?: Doc<"s">) => {
  if (!doc) return 0;
  const { labor, material, tax } = doc;
  return labor + material + tax;
};

const useProjects = <Size extends DocSize>(size: Size, where: DocWhere = {}, policy: WatchQueryFetchPolicy = "cache-and-network") => {
  // const { dateQuery, duration, ...dur } = useDuration();
  const { docs, append: appendDoc, update: updateDoc } = useDocs({ [size]: where }, policy);
  const { maxSlug } = useApiMaxSlug();
  const newSn = !maxSlug ? 0 : slugParse(maxSlug).sn + 1;
  // console.log("newSn", newSn);

  // const [fTeam, setFTeam] = React.useState("");
  // const team = (t: string | undefined = undefined) => (t === undefined ? fTeam : setFTeam(t));

  //创建项目

  const projects = [] as Project<Size>[];
  docs[size].forEach((doc, id) => {
    if (doc.sn) {
      if (doc.sn < 100) return;
      let index = projects.findIndex((p) => p.sn === doc.sn);
      if (index < 0) {
        index = projects.length;
        projects.push({
          ...projectInit,
          id,
          sn: doc.sn,
          date: doc.date,
          docsArr: [[], [], [], [], [], [], []],
        });
      }
      const typeId = types.indexOf(doc.type);
      if (typeId >= 0 && typeId < 8) projects[index].docsArr[typeId]?.push(doc as any);
    }
  });

  // 项目赋属性值
  projects.forEach((project, id) => {
    const represent = project.docsArr.flat()[0];
    // const amountDoc = project.dcsArr.reverse().flat()[0];
    const total = getTotal(project.docsArr[6][0]) || getTotal(project.docsArr[5][0]) || 0;
    if (represent) {
      // const s = doc.slug;
      projects[id] = {
        ...project,
        plate: formatPlate(represent.plate, false),
        model: formatModel(represent.model),
        vip: represent.vip,
        total,
        team: teams[represent.teamId || 0] || teams[0],
      };
    }
  });

  // pros
  //   ?.filter((p) => (!fTeam || p.team === fTeam) && p.date >= duration.start && p.date <= duration.end)
  //   .map((pro) => {
  //     const documents = DOCUMENT_TYPES.map((t) => docs?.filter((f) => f.slug === pro.slug && f.title === t) || []);
  //     const { plate, model, owner } = parseTitle(pro.title); // parseTitle((documents[0].length ? documents[0][0] : postInitMaker("document")).title);
  //     return { ...pro, plate, model, owner, documents };
  //   }) || [];

  // console.log("[useProjects]  projects =", projects);

  const appendProject = (input?: Partial<Doc>) => {
    appendDoc({ type: "r", sn: Math.max(newSn, 100), date: newDate(), ...input });
  };

  const updateProject = (project: Project<Size>) => {
    project.docsArr.flat().forEach((doc) => {
      const { sn, date, plate, model, vip, team } = project;
      const teamId = Math.max(0, teams.indexOf(team));
      // console.log("teamId", teamId, team);

      updateDoc({ ...doc, sn, date, plate, model, vip, teamId });
    });
  };
  // console.log("dcs", dcs, "projects", projects);

  return { projects, newSn, appendProject, appendDoc, updateDoc, updateProject };
};

export default useProjects;
