import { LibraryAdd } from "@mui/icons-material";
import DocTable from "./components/DocTable";
import DocTd from "./components/DocTd";
import IconBtnFly from "./inputs/DocIconBtnFly";
import useDocContext from "./useDocContext";
import useTemplates from "../hooks/useTemplates";
// import useTemplates from "../hooks/useTemplates";

const DocRequest = ({ requests, advice }: { requests: string[]; advice?: boolean }) => {
  const { imm } = useDocContext();
  const [doc, imDoc] = imm;
  const { templates } = useTemplates();
  return (
    <div style={{ position: "relative" }}>
      <DocTable noBorderTop heading={advice ? "Advice 维修建议" : "Fault Phenomenon/Repair Requirements 故障现象/送修要求"}>
        {requests.map((item, i) => {
          const tempJobs = templates.find((t) => t.request === item && t.model === doc.model)?.jobs;
          return (
            <tr key={i}>
              <DocTd width="4%">{i + 1}</DocTd>
              <DocTd left>
                {item}
                <IconBtnFly
                  MuiIcon={LibraryAdd}
                  sx={{ right: -30 }}
                  disabled={!tempJobs}
                  onClick={() => {
                    if (tempJobs)
                      imDoc((d) => {
                        d.jobs.push(...tempJobs);
                      });
                  }}
                />
              </DocTd>
            </tr>
          );
        })}
      </DocTable>
    </div>
  );
};

export default DocRequest;
