import styled from "@emotion/styled";
import React, { HTMLProps, ReactNode } from "react";

type E = <K extends string>(
  props: { keys: readonly K[]; rows: { [k in K]: React.ReactNode }[]; render?: (r: number, k: K) => ReactNode } & Omit<HTMLProps<"table">, "rows">
) => JSX.Element;

const PureTable: E = ({ keys, rows, render, ...ps }) => {
  return (
    <table>
      <thead>
        <tr>
          {keys.map((header, k) => (
            <th key={k}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, r) => (
          <tr key={r}>
            {keys.map((key, k) => (
              <td key={k}>{render ? render(r, key) : row[key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PureTable;
