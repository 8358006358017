import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

const PageRefresh = () => {
  const navigate = useNavigate();
  React.useEffect(() => navigate(-1));
  return null;
};

export default PageRefresh;
