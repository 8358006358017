import React from "react";
import useRecord from "../api/record/useRecord";
import useRecs from "../api/record/useRecs";
import useRecordMutation from "../api/record/useRecordMut";
const mon = new Date().getMonth();

const usePayrolls = () => {
  let names = [] as string[];
  for (let i = 1; i <= mon + 1; i++) {
    names = [...names, `payroll-2023-${`${i}`.padStart(2, "0")}`];
  }
  const { recs, refetch } = useRecs({ nameIn: names });
  const { append } = useRecordMutation(refetch);
  return { names, recs, append };
};

export default usePayrolls;
