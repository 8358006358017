import React from "react";
import XData from "../../layout/XData";
// import useTemplates from "../../hooks/useTemplates";
import DataGrid from "../../components/data-display/DataGrid";
import Btn from "../../components/inputs-pure/Btn";
import { useNavigate } from "react-router-dom";
import useTemplates from "../../hooks/useTemplates";
import Layout from "../../layout/Layout";
import { formatModel } from "../../utils/format";

const PageTemplates = () => {
  const { templates } = useTemplates();
  const navigate = useNavigate();
  return (
    <Layout title="Tulando Templates">
      <DataGrid
        columns={[
          { field: "sn", renderCell: (ps) => (ps.row.sn > 100 ? <Btn children={ps.row.sn} onClick={() => navigate(`/document/${ps.id}`)} /> : ps.row.sn) },
          { field: "model", width: 180, renderCell: (ps) => formatModel(ps.value) },
          { field: "request", flex: 1 },
          {
            field: "more",
            headerName: "",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (ps) => <Btn children="Edit" onClick={() => navigate(`/template/${ps.id}`)} />,
          },
        ]}
        rows={templates}
      />
    </Layout>
  );
};

export default PageTemplates;
