import { gql, useMutation } from "@apollo/client";
import { LoginMutation, LoginMutationVariables, RefreshTokenMutation, RefreshTokenMutationVariables } from "../types/graphql";

export const mutationLogin = gql`
  mutation Login($password: String = "", $username: String = "") {
    login(input: { clientMutationId: "tulandoSys", password: $password, username: $username }) {
      authToken
      refreshToken
      user {
        id
        name
      }
    }
  }
`;

export const mutationRefreshToken = gql`
  mutation RefreshToken($jwtRefreshToken: String!) {
    refreshJwtAuthToken(input: { jwtRefreshToken: $jwtRefreshToken, clientMutationId: "tulandoSys" }) {
      authToken
    }
  }
`;

export const useMutAuth = () => {
  const [login] = useMutation<LoginMutation, LoginMutationVariables>(mutationLogin);
  const [refresh] = useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(mutationRefreshToken);
  return { login, refresh };
};