import { useImmer } from "use-immer";
import BtnDlg from "../components/BtnDlg";
import Input from "../components/Input";
import Pick from "../components/Pick";
import { Check } from "@mui/icons-material";
import { Project, projectInit } from "../v6/useProjects";
import { teams } from "../v6/docs/constant-title";
// import useOptions from "../hooks/useOptions.ts.bak";

// const formInit = { plate: "", model: "", vip: "" };
// export type DlgProjectForm = typeof formInit;

const BtnDlgProject = ({ title = "", label = "", defaultValue = projectInit, onConfirm = (form: Project<"s">) => form as any }) => {
  const imm = useImmer(defaultValue);
  // const options = useOptions();
  const [_form, setForm] = imm;
  return (
    <BtnDlg
      title={title}
      label={label}
      btnProps={[
        {
          startIcon: <Check />,
          label: "confirm",
          onClick: () => {
            // console.log(_form);
            onConfirm(_form);
          },
        },
      ]}
    >
      <Input type="number" imm={imm} field="sn" />
      <Input type="date" imm={imm} field="date" />
      <Input imm={imm} field="plate" />
      <Input imm={imm} field="model" />
      <Input imm={imm} field="vip" />
      {/* <Input type="number" imm={imm} field="total" /> */}
      <Pick imm={imm} field="team" itemProps={teams.map((t) => ({ children: t, value: t }))} />
    </BtnDlg>
  );
};

export default BtnDlgProject;
