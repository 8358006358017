import React from "react";
import { Link } from "react-router-dom";
import usePayrolls from "../../hooks/usePayrolls";
import Btn from "../../components/inputs-pure/Btn";
import BtnDlg from "../../components/BtnDlg";
import { Add } from "@mui/icons-material";
import { excerptInit } from "../../hooks/usePayroll";

const PagePayrolls = () => {
  const { names, recs, append } = usePayrolls();
  // console.log("rolls", names, recs);

  return (
    <div>
      PagePayrolls
      {names.map((slug) => (
        <p>
          {recs.find((r) => r.slug === slug) ? (
            <Link to={`/payroll/${slug.slice(slug.indexOf("2"))}`}>{slug}</Link>
          ) : (
            <span>
              {`append ${slug}`}
              <BtnDlg label="+" title={`Create ${slug}`} btnProps={[{ label: "add", startIcon: <Add />, onClick: () => append({ slug, excerpt: JSON.stringify(excerptInit) }) }]} />
            </span>
          )}
        </p>
      ))}
    </div>
  );
};

export default PagePayrolls;
