import React from "react";
import Pick from "../../components/Pick";
import useApiDocs, { ApiDoc } from "../../v6/useApiDocs";
import Btn from "../../components/inputs-pure/Btn";
import { Stack } from "@mui/material";
import BtnDlg from "../../components/BtnDlg";
import { gql, useMutation, useQuery } from "@apollo/client";
import { titleParse, titleSerialize } from "../../v6/docs/constant-title";

const PageCopyField = () => {
  const { apiDocs, update } = useApiDocs({ f: {} });
  // console.log("f1,f2,apiDocs", f1, f2, apiDocs);
  const handleUpdate = () =>
    apiDocs.f.map(({ id, ...d }) => {
      const titleObj = titleParse(d.title);
      // const newSlug = `${titleObj.sn.toString().padStart(6, "0")}${titleObj.type}`;
      const title = titleSerialize(titleObj);
      // const newTitle = titleSerialize(info).replaceAll("_", " ");
      console.log("updating id:", id, title);
      // 危险 Dangerous ***********************
      // update({ id, title });
      // 危险 Dangerous ***********************
    });
  return (
    <Stack sx={{ height: "100vh" }} justifyContent="center" alignItems="center">
      {/* copy filed
      <Pick value={f1} onChange={(e) => setF1(e.target.value as keyof ApiDoc)} itemProps={["sn", "title", "content"].map((f) => ({ value: f, children: f }))} />
      to filed
      <Pick value={f2} onChange={(e) => setF2(e.target.value as keyof ApiDoc)} itemProps={["sn", "title", "content"].map((f) => ({ value: f, children: f }))} /> */}
      <BtnDlg
        title="operate database now"
        label="operate database now"
        color="error"
        btnProps={[
          {
            variant: "contained",
            color: "error",
            label: "it is very dangerous",
            onClick: handleUpdate,
          },
        ]}
      />
    </Stack>
  );
};

export default PageCopyField;
