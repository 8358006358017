import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import { DocContextProvider } from "../../docPrint/useDocContext";
import useTemplates from "../../hooks/useTemplates";
import Toolbar from "../../layout/Toolbar";
import ToolbarNavigate from "../../layout/ToolbarNavigate";
import { Box, Divider } from "@mui/material";
import { BtnDlgClone, BtnDlgDelete } from "../../components/BtnDlg";
import { BtnSave } from "../../components/inputs-pure/Btn";
import DocPaper from "../../docPrint/components/DocPaper";
import DocSn from "../../docPrint/DocSnDate";
import DocTable from "../../docPrint/components/DocTable";
import DocTdLabel from "../../docPrint/components/DocTdLabel";
import DocTd from "../../docPrint/components/DocTd";
import DocAuto from "../../docPrint/inputs/DocAuto";
import { deduplicateVar } from "../../utils/deduplicate";
import DocItems from "../../docPrint/DocItems";
import { formatAmount, formatModel } from "../../utils/format";
import Pop from "../../docPrint/inputs/Pop";
import DocInput from "../../docPrint/inputs/DocInput";
import { jobsSummary } from "../../utils/jobsSummary";
import useDoc from "../../v6/docs/useDoc";
import { makeVar } from "@apollo/client";
import { docInit } from "../../v6/docs/constant-doc";

const varTemplate = makeVar(docInit.f);
const varEdited = makeVar(false);

const PageTemplate = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { imm, doc, remove, update, edited, append } = useDoc(slug || "", varTemplate, varEdited);
  const options = useTemplates(doc.model);
  const { subtotal } = jobsSummary(doc.jobs);
  const total = subtotal * 1.16;
  const hasRequest = doc.requests.length === 1;
  return (
    <Layout title={`Template-${doc.model}-${doc.requests[0]}`} alignItems="center">
      <DocContextProvider imm={imm} disabled={false} options={options}>
        <Toolbar>
          <ToolbarNavigate warning={edited} urlBack="/templates" />

          <Box flexGrow={1} />
          <BtnDlgDelete onClick={() => remove().then(() => navigate("/templates"))} />
          {/* <BtnDlgTemplate
            onClick={
              () => update({ ...doc, statusId: 2 })
              // imm[1]((d) => {
              //   d.statusId = 2;
              // })
            }
            disabled={doc.statusId === 2}
          /> */}
          <BtnDlgClone onClone={(type) => append({ ...doc, type }).then((res) => navigate(`/template/${res.slug}`))} />

          <BtnSave onClick={() => update()} disabled={!edited} />
        </Toolbar>
        <Divider className="no-print" sx={{ width: "100%" }} />
        <DocPaper>
          <div style={{ position: "relative" }}>
            <Pop sx={{ left: -30, top: -6 }}>
              <DocInput field="sn" type="number" autoFocus />
            </Pop>
            <h6 style={{ textAlign: "left" }}>No. 编号：{doc.sn} </h6>
          </div>
          <DocTable heading="Model and Request 车型和需求">
            <tr>
              {/* <DocTdLabel width="15%" en="Model &" br zh="request" /> */}
              <DocTd width="17%">
                <DocAuto multiline options={deduplicateVar(options.vehicles.map((v) => v.model))} field="model" format={formatModel} />
              </DocTd>
              <DocTd>
                {hasRequest ? (
                  <DocAuto
                    multiline
                    textAlign="left"
                    options={options.requests}
                    value={doc.requests[0]}
                    onInputDone={(v) =>
                      imm[1]((draft) => {
                        draft.requests = [v];
                      })
                    }
                  />
                ) : (
                  "unavailable"
                )}
              </DocTd>
            </tr>
          </DocTable>
          <DocItems full />
          <div style={{ position: "relative", textAlign: "right", padding: "5px 0" }}>
            <small style={{ color: "gray" }}>subtotal:</small> {formatAmount(subtotal)} <small style={{ color: "gray" }}>+16%:</small> {formatAmount(total)}
          </div>
        </DocPaper>
      </DocContextProvider>
    </Layout>
  );
};

export default PageTemplate;
