import React from "react";
import { ApiDocsQueryVariables, PostStatusEnum } from "../../api/types/graphql";
import useApiDocs from "../useApiDocs";
import { Doc, docParse, docCreateInput, docUpdateInput, docInit } from "./constant-doc";
import { makeVar, ReactiveVar, useReactiveVar, WatchQueryFetchPolicy } from "@apollo/client";
import useDocs from "./useDocs";
import { DraftFunction, ImmerHook } from "use-immer";
import { produce } from "immer";

export const varDoc = makeVar(docInit.f);
export const varEdited = makeVar(false);

export const useDoc = (slug: string, _varDoc: ReactiveVar<Doc> = varDoc, _varEdited: ReactiveVar<boolean> = varEdited, fetchPolicy?: WatchQueryFetchPolicy) => {
  const doc = useReactiveVar(_varDoc);
  const edited = useReactiveVar(_varEdited);
  const setDoc = (value: Doc) => {
    _varDoc(value);
    _varEdited(true);
  };
  const imDoc = (draftFunc: DraftFunction<Doc>) => {
    _varDoc(
      produce(doc, (draft) => {
        draftFunc(draft);
      })
    );
    _varEdited(true);
  };
  const imm = [doc, imDoc] as ImmerHook<Doc>;
  const state = [doc, setDoc];
  const { docs, append: _a, update: _u, remove: _r } = useDocs({ f: { name: slug } }, fetchPolicy || "network-only");
  React.useEffect(() => {
    if (docs.f[0]) {
      _varDoc(docs.f[0]);
      _varEdited(false);
    }
  }, [docs]);
  const append = (d?: Partial<Doc>) => _a({ ...doc, ...d });
  const update = (d?: Partial<Doc>) => {
    const res = _u({ ...doc, ...d });
    _varEdited(false);
    return res;
  };
  const remove = () => {
    const res = _r(doc);
    _varEdited(false);
    return res;
  };

  return { doc, imm, state, edited, append, update, remove };
};

export default useDoc;
