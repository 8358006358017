import { Delete, North, NorthWest, South, SouthWest } from "@mui/icons-material";

import DocTd from "./components/DocTd";
import DocAuto from "./inputs/DocAuto";
import RowMenu from "./inputs/RowMenu";
import { Box, FilledInput, Input, TextField } from "@mui/material";
import useDocContext from "./useDocContext";
import DocInput from "./inputs/DocInput";
import { formatAmount } from "../utils/format";
import { excerptInit, matInit } from "../v6/docs/constant-excerpt";
import { deduplicateVar } from "../utils/deduplicate";

// const docMatInit = excerptInit.jobs[0].mats[0];

type P = { jobId: number; matId: number; full?: boolean; showLabel?: boolean };
const DocItemsJobMat = ({ jobId, matId, full, showLabel }: P) => {
  const { imm, disabled, options } = useDocContext();

  // console.log("options", options);

  const [doc, imDoc] = imm;
  const job = doc.jobs && doc.jobs[jobId];
  const mat = job?.mats[matId];
  const exchange = doc.exchange && doc.currency && doc.exchange[doc.currency];
  return (
    <>
      {showLabel && (
        <>
          <td>
            <DocAuto // mat / code
              // readOnly={disabled}
              // textAlign="center"
              options={deduplicateVar(options.materials.map((m) => m.code).filter((f) => f))} // values("mat", "code")}
              value={mat.code}
              onInputDone={(v) =>
                imDoc((d) => {
                  d.jobs[jobId].mats[matId].code = v;
                })
              }
              onPick={(v) => {
                imDoc((d) => {
                  const pre = options.materials.find((m) => v === m.code); // find("mat", v, "code");
                  if (pre) if (pre) d.jobs[jobId].mats[matId] = { ...pre };
                });
              }}
            />
          </td>
          <DocTd>
            <DocAuto
              multiline //
              options={deduplicateVar(options.materials.map((m) => m.description).filter((f) => f))}
              value={mat.description}
              onInputDone={(v) =>
                imDoc((d) => {
                  d.jobs[jobId].mats[matId].description = v;
                })
              }
              onPick={(v) => {
                imDoc((d) => {
                  const pre = options.materials.find((m) => v === m.description); // find("mat", v, "code");
                  if (pre) if (pre) d.jobs[jobId].mats[matId] = { ...pre };
                });
              }}
            />
          </DocTd>
        </>
      )}
      <td>
        <DocAuto // mat / label
          multiline
          readOnly={disabled}
          textAlign="center"
          options={options.materials.map((m) => m.label)}
          value={mat.label}
          onInputDone={(v) =>
            imDoc((d) => {
              d.jobs[jobId].mats[matId].label = v;
            })
          }
          onPick={(v) =>
            imDoc((d) => {
              const pre = options.materials.find((m) => m.label === v);
              if (pre) d.jobs[jobId].mats[matId] = { ...pre };
            })
          }
        />
      </td>
      <DocTd>
        <RowMenu
          disabled={disabled}
          sx={{ right: -30 }}
          clicked={(btn) =>
            imDoc((w) => {
              // btn === "del" && w.jobs[jobId].mats.splice(matId, 0, matInit);
              btn === "del" && w.jobs[jobId].mats.splice(matId, 1);
              btn === "mvUp" && w.jobs[jobId].mats.splice(matId - 1, 0, w.jobs[jobId].mats.splice(matId, 1)[0]);
              btn === "mvDn" && w.jobs[jobId].mats.splice(matId + 1, 0, w.jobs[jobId].mats.splice(matId, 1)[0]);
              btn === "inUp" && w.jobs[jobId].mats.splice(matId, 0, matInit);
              btn === "inDn" && w.jobs[jobId].mats.splice(matId + 1, 0, matInit);
            })
          }
          disable={{
            mvUp: matId === 0,
            mvDn: matId === job.mats.length - 1,
          }}
        />
        {disabled || !full || (
          <Box component="span" className="no-print" sx={{ position: "absolute", right: -100 }}>
            <FilledInput
              // variant="filled"

              size="small"
              inputProps={{ sx: { width: 60, p: 0.2 } }}
              type="number"
              value={mat.cost}
              onChange={(e) =>
                imDoc((d) => {
                  d.jobs[jobId].mats[matId].cost = +e.target.value;
                })
              }
            />
          </Box>
        )}
        <DocInput // mat / qty
          textAlign="right"
          value={mat.qty}
          format={formatAmount}
          onInputDone={(v) =>
            imDoc((d) => {
              d.jobs[jobId].mats[matId].qty = +v;
            })
          }
        />
      </DocTd>
      {full && (
        <td>
          <DocInput // mat / rate
            textAlign="right"
            value={mat.rate}
            format={formatAmount}
            onInputDone={(v) =>
              imDoc((d) => {
                d.jobs[jobId].mats[matId].rate = +v;
                if (mat.qty < 1) d.jobs[jobId].mats[matId].qty = 1;
              })
            }
          />
        </td>
      )}
      {full && <DocTd right>{formatAmount(mat.qty * mat.rate) || "-"}</DocTd>}
    </>
  );
};

export default DocItemsJobMat;
