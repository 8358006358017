import { WatchQueryFetchPolicy, gql, useMutation, useQuery } from "@apollo/client";
import {
  ApiDocsQuery,
  ApiDocsQueryVariables,
  CreateDocInput,
  CreateDocMutation,
  CreateDocMutationVariables,
  DeleteDocMutation,
  DeleteDocMutationVariables,
  PostStatusEnum,
  UpdateDocInput,
  UpdateDocMutation,
  UpdateDocMutationVariables,
} from "../api/types/graphql";
import React from "react";
import { newDate } from "../utils/useDuration";

const queryApiDocs = gql`
  query ApiDocs(
    $s: RootQueryToDocConnectionWhereArgs = { id: 1 }
    $m: RootQueryToDocConnectionWhereArgs = { id: 1 }
    $f: RootQueryToDocConnectionWhereArgs = { id: 1 }
  ) {
    s: docs(first: 100000, where: $s) {
      nodes {
        id
        title
      }
    }
    m: docs(first: 100000, where: $m) {
      nodes {
        id
        title
        slug
        excerpt(format: RAW)
      }
    }
    f: docs(first: 100000, where: $f) {
      nodes {
        id #系统功能
        slug #编号
        title #项目类型-编号-项目日期-车牌-车型-车主-组-工费-材料费-税-成本 (概要数据)
        status # (draft 草稿, private 定稿, public 模版）
        date #文档日期
        excerpt(format: RAW) #自定义数据结构
        content #保留
      }
    }
  }
`;

const mutationCreateDoc = gql`
  mutation CreateDoc($input: CreateDocInput = {}) {
    createDoc(input: $input) {
      doc {
        id
        slug
      }
    }
  }
`;
const mutationDeleteDoc = gql`
  mutation DeleteDoc($input: DeleteDocInput = { id: "" }) {
    deleteDoc(input: $input) {
      deletedId
      doc {
        id
        slug
      }
    }
  }
`;

const mutationUpdateDoc = gql`
  mutation UpdateDoc($input: UpdateDocInput = { id: "" }) {
    updateDoc(input: $input) {
      doc {
        id
        slug
      }
    }
  }
`;

type SApiDoc = { id: string; title: string };
type MApiDoc = SApiDoc & { slug: string; excerpt: string };
type FApiDoc = MApiDoc & { date: string; status: PostStatusEnum; content: string };

export type DocSize = keyof ApiDocsQueryVariables;
export type DocWhere = ApiDocsQueryVariables[DocSize];
export type ApiDoc<Size extends DocSize = "f"> = (typeof apiDocInit)[Size];
const apiDocInit = {
  s: { id: "", title: "" },
  m: { id: "", title: "", slug: "", excerpt: "" },
  f: { id: "", title: "", slug: "", excerpt: "", date: newDate(), status: PostStatusEnum.Draft, content: "" },
};
// type Doc<R extends Range> = R extends Range.title ? DocTitle : R extends Range.excerpt ? DocExcerpt : DocFull;

const useApiDocs = (where: ApiDocsQueryVariables, fetchPolicy?: WatchQueryFetchPolicy) => {
  const { s, m, f, ...rest } = where;
  const stati = [PostStatusEnum.Draft, PostStatusEnum.Publish];
  const variables = {
    ...(s && { s: { stati, ...s } }),
    ...(m && { m: { stati, ...m } }),
    ...(f && { f: { stati, ...f } }),
    ...rest,
  };
  // console.log("variables", variables);

  const { data, refetch, loading: lq } = useQuery<ApiDocsQuery, ApiDocsQueryVariables>(queryApiDocs, { variables, fetchPolicy });

  const apiDocs = {
    s: React.useMemo(() => data?.s?.nodes.map(({ __typename, ...node }) => node as ApiDoc<"s">) || [], [data]),
    m: React.useMemo(() => data?.m?.nodes.map(({ __typename, ...node }) => node as ApiDoc<"m">) || [], [data]),
    f: React.useMemo(() => data?.f?.nodes.map(({ __typename, ...node }) => node as ApiDoc<"f">) || [], [data]),
  };

  const [_append, { loading: la }] = useMutation<CreateDocMutation, CreateDocMutationVariables>(mutationCreateDoc);
  const [_remove, { loading: ld }] = useMutation<DeleteDocMutation, DeleteDocMutationVariables>(mutationDeleteDoc);
  const [_update, { loading: lu }] = useMutation<UpdateDocMutation, UpdateDocMutationVariables>(mutationUpdateDoc);

  const append = async (input: CreateDocInput) => {
    const { data } = await _append({ variables: { input } });
    await refetch();
    return { id: data?.createDoc?.doc?.id, slug: data?.createDoc?.doc?.slug || undefined };
  };

  const remove = async ({ id }: { id: string }) => {
    const { data } = await _remove({ variables: { input: { id } } });
    await refetch();
    return { id: data?.deleteDoc?.doc?.id, slug: data?.deleteDoc?.doc?.slug || undefined };
  };

  const update = async (input: UpdateDocInput) => {
    const { data } = await _update({ variables: { input } });
    await refetch();
    return { id: data?.updateDoc?.doc?.id, slug: data?.updateDoc?.doc?.slug || undefined };
  };

  // const newSn = 1 + dcs.reduce((p, c) => Math.max(p, +c.sn || 0), 0);

  return { apiDocs, append, remove, update, loading: lq || la || lu || ld };
};

export default useApiDocs;
