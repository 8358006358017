import { GraphQLError } from "graphql";
import React from "react";
import authContext from "./authContext";

const useAuth = () => {
  const auth = React.useContext(authContext);
  React.useEffect(() => {
    auth._refreshToken();
    if (auth.errors) alert(auth.errors);
  }, []);
  return auth;
};

export default useAuth;
