import React from "react";
import Btn, { BtnProps } from "../components/inputs-pure/Btn";
import BtnMenu from "../components/BtnMenu";
import { Type, titleSerialize, types, types_zh } from "../v6/docs/constant-title";
import { Doc } from "../v6/docs/constant-doc";
import { slugSerialize } from "../v6/docs/constant-slug";

type P = { disabled?: boolean; atType?: Type; docsArr: Doc<"s">[][]; clickUrl: (url: string) => any; clickAppend: (type: Type) => any };
const DocsBtns = ({ disabled, atType, docsArr, clickUrl, clickAppend }: P) => {
  return (
    <span>
      {/* <DocsBtns docs={row.docs} /> */}
      {types.map((type, typeId) => {
        const docs = docsArr[typeId];
        const count = docs?.length || 0;
        const ps: BtnProps = {
          key: typeId,
          active: !!count && docsArr[typeId + 1]?.length === 0,
          // color: docs[0] ? (["success", "inherit", "secondary"] as const)[docs[0].statusId] : "inherit",
          // active: !!count && docs?.filter((d) => d.status === PostStatusEnum.Publish).length !== count,
          mute: !count,
          style: atType && type === atType ? { boxShadow: "0px 3px 0px #1a76d2" } : undefined,
          disabled,
          children: `${types_zh[typeId].charAt(0)}${count > 1 ? `${count}` : ""}`,
        };
        if (count === 1) return <Btn {...ps} onClick={() => clickUrl(`/document/${slugSerialize(docs[0])}`)} />;

        return (
          <BtnMenu
            {...ps}
            itemsProps={[
              ...docs.map((doc) => ({
                // sx: {
                //   backgroundColor: doc.statusId === 0 ? undefined : "#ddd",
                // },
                children: titleSerialize(doc),
                onClick: () => clickUrl(`/document/${slugSerialize(doc)}`),
              })),
              {
                children: "+ Append",
                onClick: () => clickAppend(type),
              },
            ]}
          />
        );
      })}
    </span>
  );
};

export default DocsBtns;
