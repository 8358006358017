import { Print, Save } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";

export type BtnProps = ButtonProps & { label?: React.ReactNode; active?: boolean; mute?: boolean };

const Btn = ({ label, active, mute, ...ps }: BtnProps) => {
  return (
    <Button
      color="inherit"
      children={label}
      {...ps}
      sx={{
        py: 0,
        px: 1,
        minWidth: 20,
        // color: active ? "#000" : undefined,
        backgroundColor: active ? "#ddd" : undefined,
        opacity: mute ? 0.33 : undefined,
      }}
    />
  );
};

export const BtnPrint = (ps: BtnProps) => <Btn label="print" startIcon={<Print />} color="warning" {...ps} />;
export const BtnSave = (ps: BtnProps) => {
  useHotkeys(["ctrl+s", "meta+s"], (e: any) => !ps.disabled && ps.onClick && ps.onClick(e), { preventDefault: true });
  return <Btn label="save" startIcon={<Save />} color="success" {...ps} />;
};

export default Btn;
