import { FormControl, InputLabel, MenuItem, MenuItemProps, Select, SelectProps } from "@mui/material";
import React from "react";
import { ImmerHook } from "use-immer";

// export type PickProps = SelectProps & { imm?: ImmerHook<Object>; field?: string; width?: number | string; label?: string; itemProps: MenuItemProps[] };

type E = <V extends number | string>(
  props: {
    value: V;
    values?: readonly V[];
    width?: number | string;
    label?: string;
    itemProps?: (MenuItemProps & { value: V })[];
    onPick: (v: V) => void;
  } & Omit<SelectProps, "onChange">
) => JSX.Element;

const Pick: E = ({ children, width, itemProps, value, values, onPick, ...ps }) => {
  return (
    <Select
      size="small"
      {...ps}
      sx={{ border: 0, ...ps.sx }}
      value={value}
      onChange={(e) => {
        const v = e.target.value as any;
        onPick((typeof value === "number" ? +v : v) as any);
      }}
    >
      {itemProps && itemProps.map((itemProp, i) => <MenuItem key={i} {...itemProp} />)}
      {values && values.map((v, i) => <MenuItem key={i} value={v} children={v} />)}
    </Select>
  );
};

export default Pick;
