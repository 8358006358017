import React from "react";
import useAuth from "../api/auth/useAuth";
import { Box, Button, InputAdornment, Stack, TextField } from "@mui/material";
import { AccountCircle, Key } from "@mui/icons-material";
import { Link } from "react-router-dom";

const PageLogin = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { onLogin, errors } = useAuth();
  const handleLogin = () => onLogin(username, password);
  if (errors) console.log(errors);

  return (
    <form name="login">
      <Box display="flex" height="100vh" alignItems="center" justifyContent="center">
        <Stack spacing={1}>
          <img src="/head2.png" alt="" style={{ width: "360px", marginBottom: "12px", opacity: 0.8 }} />
          <input type="hidden" id="username" name="username" value="tulando-user" style={{ display: "none" }} />
          <TextField
            fullWidth
            id="username"
            name="username"
            value={username}
            type="text"
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{ startAdornment: <InputAdornment position="start" children={<AccountCircle />} /> }}
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{ startAdornment: <InputAdornment position="start" children={<Key />} /> }}
          />{" "}
          <Button variant="outlined" color="inherit" type="button" onClick={handleLogin}>
            login
          </Button>
          <Link to='/' style={{ opacity: 0.5, paddingTop: 8, textAlign: "center" }}>Back to Home</Link>
        </Stack>
      </Box>
    </form>
  );
};

export default PageLogin;
