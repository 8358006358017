import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from "@mui/material";
import { useParams } from "react-router-dom";
import { newDate } from "../../utils/useDuration";
import useJournal from "../../hooks/journal/useJournal";

const PageJournal = () => {
  const { date } = useParams();
  const { headers, entries, summaries, rows, balance } = useJournal(date || newDate());
  return (
    <div>
      <h3>Financial Record for {date}</h3>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">sn</TableCell>
            {headers.map((headers) => (
              <TableCell align="center">{headers}</TableCell>
            ))}
            <TableCell align="center">balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow>
              <TableCell align="center">{i + 1}</TableCell>
              {row.map((cell) => (
                <TableCell align={typeof cell === "number" ? "right" : undefined}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            {summaries.map((summary) => (
              <TableCell align="right">{summary}</TableCell>
            ))}
            <TableCell></TableCell>
            <TableCell align="right">{balance}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      {/* <CsvPreview title="Journal" cols={["sn", "date", "type"]} rows={journal.rows.map((row, sn) => ({ sn, ...row }))} /> */}
    </div>
  );
};
export default PageJournal;
