/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable react/require-default-props */

export type DocTdProps = {
  left?: boolean;
  right?: boolean;
  // textAlign?: React.CSSProperties['justifyContent'];
} & React.ComponentProps<"td">;

const DocTd = ({ children, left, right, ...rest }: DocTdProps) => (
  <td {...rest}>
    <div
      style={{
        justifyContent: left ? "start" : right ? "end" : "center",
      }}
    >
      {children}
    </div>
  </td>
);
export default DocTd;
