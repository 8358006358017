/* eslint-disable react/jsx-props-no-spreading */
import { Translate } from "@mui/icons-material";
import { Stack, styled } from "@mui/material";

import { ComponentProps } from "react";
import DocTable from "./components/DocTable";
import IconBtnFly from "./inputs/DocIconBtnFly";
import DocText from "./inputs/DocInput";
import useDocContext from "./useDocContext";
// import InvoiceTable, { TxtIn } from '../InvoiceTable';

const Ol = styled("ol")({
  fontSize: 11,
  fontWeight: 600,
  color: "#0070c0",
  paddingInlineStart: 20,
  marginTop: 5,
  marginBottom: 0,
  "& li": { fontSize: 11, textAlign: "left", width: "100%" },
});

const Doc5Notice = () => {
  const { imm, disabled } = useDocContext();
  const [doc, imDoc] = imm;
  return (
    <DocTable
      heading={
        <div style={{ height: "26px" }}>
          <i>
            <strong>Thanks for your supporting/谢谢您的惠顾</strong>
          </i>
          <br />
          <i>Please pay attention to the notice below 请注意以下提示: </i>
        </div>
      }
      style={{ height: "100%" }}
    >
      <tr>
        <td>
          {disabled || (
            <IconBtnFly
              sx={{ left: -30 }}
              // color={!doc.meta?.show?.head ? "warning" : "primary"}
              MuiIcon={Translate}
              onClick={() =>
                imDoc((d) => {
                  if (!doc.lang) d.lang = "ZH" as any;
                  else d.lang = doc.lang === "ZH" ? "EN" : "ZH";
                })
              }
            />
          )}
          <Stack>
            {doc.lang === "EN" ? (
              <Ol>
                <li> We always provide wiper water for free, can come back to top up at any time if needed;</li>
                <li>
                  We give 3 months or 3000km warranty to Supreme customer, and 1 month or 1000km to Diamond customers, (whichever comes first). Can came back
                  for free inspection if any problems occur during the warranty period. If it is verified that the problems caused by repair faults or the
                  quality of the parts, we will do them for free; If the customer goes to another garage to check, repair, remove out or change some parts
                  before check in our workshop, we will no longer provide warranty;
                </li>
                <li>
                  We allow customers to bring their own parts, without additional charge, but we don't provide warranty; and if any extra job, return job, or
                  other problems caused by the quality, model, size, or any fault of the parts, we need to charge based on the actual working time or workload;
                </li>
                <li>
                  Electrical parts (such as alternators, starter motors, injector nozzles, fuel pumps, etc.) are recommended to be replaced with new ones. If
                  customers choose to repair or replace second-hand parts, no warranty can be provided;
                </li>
                <li> If customers choose to buy secondhand parts, do not provide warranty.</li>
                <li>
                  Before collect the vehicle, please do test the vehicle to make sure there is no omissions for the repair projects and the vehicle is in a good
                  condition, and check if the parts other than the repaired project are damaged or lost. Once signed to confirm, we don't take any
                  responsibility for the parts which other than the repaired project.
                </li>
              </Ol>
            ) : (
              <Ol>
                <li>本店永久免费赠送雨刮水，用完可随时返回添加；</li>
                <li>
                  本店给予至尊卡客户3个月或3000km的质保，钻石卡客户1个月或1000km的质保，时间或里程，以先到者为准；以上所有维修项目若在保修期内出现任何问题，均可返店免费检查，若因本店维修失误或配件质量问题导致，经查证属实，本店将负责免费维修；
                </li>
                <li>
                  本店允许客户自带配件，不加收工时费，但不提供质保；因配件质量、型号、尺寸问题导致的多次拆装、返工及其他问题，需以实际工作时间或工作量收取工费；
                </li>
                <li>若在本店检查确认之前，经过外店拆装维修的，本店不再提供质保，也不对外店拆修的部分或可能引发的后续问题承担责任；</li>
                <li>电气类配件(如发电机, 启动机, 喷油嘴, 油泵等)，若客户选择维修或更换二手件，不提供质保；</li>
                <li>二手拆车件，客户指定(地点或品牌)采购的配件，不提供质保；</li>
                <li style={{ color: "#2e7d32" }}>
                  结账之前请务必试车，以确定托修项目无遗漏，车辆处于良好的运行状态，并检查托修项目以外的部分是否有损伤、遗失等现象。一经签字确认后，本店不再对托修项目之外的部分承担任何责任。{" "}
                </li>
              </Ol>
            )}
            <ul
              style={{
                width: "100%",
                fontSize: 11,
                paddingInlineStart: 0,
                margin: 0,
                listStyle: "none",
              }}
            >
              <li>
                <DocText
                  multiline
                  textAlign="left"
                  value={doc.notice}
                  sx={{ color: "#bb0000" }}
                  onChange={(e) =>
                    imDoc((d) => {
                      d.notice = e.target.value;
                    })
                  }
                />
              </li>
            </ul>
          </Stack>
        </td>
      </tr>
    </DocTable>
  );
};

export default Doc5Notice;
