import styled from "@emotion/styled";
import React from "react";
import PureTable from "./PureTable";
import { formatAmount } from "../../utils/format";
import { JsxElement } from "typescript";

const Table = styled("div")`
  table {
    border-collapse: collapse;
    border: 1px solid black;

    td,
    th {
      // padding: 0 1px;
      border: 1px solid black;
      div {
        display: flex;
        align-items: center;
        height: 45px;
      }
    }
    td:nth-child(8),
    th:nth-child(8) {
      background-color: #f2f085;
    }
    td:nth-child(13),
    th:nth-child(13) {
      background-color: #ecccac;
    }
    td:nth-child(17),
    th:nth-child(17) {
      background-color: #cfecb2;
    }
    td:nth-child(20),
    th:nth-child(20) {
      background-color: #add1ee;
    }
  }
`;

type F = <K extends string>(props: { slug: string; keys: readonly K[]; rows: { [k in K]: any }[] }) => JSX.Element;
const PayrollTable: F = ({ slug, keys, rows }) => {
  return (
    <div>
      <h3 style={{ justifyContent: "center" }}>
        <i>EMPLOYEE PAYROLL OF TULANDO {slug}</i>
      </h3>
      <Table>
        <PureTable
          keys={keys}
          rows={rows}
          render={(r, k) => {
            if (k === "sn.") return <div style={{ justifyContent: "center" }}> {r + 1}</div>;
            if (k === "name") return <div style={{ justifyContent: "center" }}> {rows[r][k]}</div>;
            if (k === "Confirmed by Signature") return "";
            return <div style={{ justifyContent: "right" }}>{formatAmount(rows[r][k])}</div>;
          }}
        />
      </Table>
      <small>Remark: Lunch Provided</small>
    </div>
  );
};

export default PayrollTable;
