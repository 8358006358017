import { gql, useQuery } from "@apollo/client";
import React from "react";
import useApiDocs from "../v6/useApiDocs";
import { PostStatusEnum } from "../api/types/graphql";
import useApiMaxSlug from "../v6/useApiMaxSlug";
import useDocs from "../v6/docs/useDocs";
import { DraftFunction, ImmerHook } from "use-immer";
import objectFunc from "../utils/objectFunc";

type Sample = typeof sample;
const sample = {
  rows: [
    { a: 1, b: 2 },
    { a: 3, b: 4 },
  ],
};
const imm: ImmerHook<Sample> = [sample, (arg: Sample | DraftFunction<Sample>) => {}];

const PageTest = () => {
  // const { docs } = useDocs("s", { nameIn: ["r-230012"] });
  // const { maxSn } = useApiSn();
  // console.log("docs", docs);
  objectFunc(imm, 12, "rows", 0, "a");
  console.log("sample-imm", objectFunc(imm));

  return <div>PageTest</div>;
};

export default PageTest;
