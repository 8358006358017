const jsonParse = (jsonStr?: string | null): any => {
  // let obj: object = [];
  if (!jsonStr) return undefined;
  let obj: any;
  try {
    obj = JSON.parse(jsonStr);
  } catch (e) {
    console.info("::: JSON.parse Error :::"); //, jsonStr);
  }
  return obj;
};
export default jsonParse;
