import { add, format, lastDayOfMonth } from "date-fns";
import React from "react";
import { DateQueryInput } from "../api/types/graphql";
import { makeVar, useReactiveVar } from "@apollo/client";

export const newDate = (input: string | number | Date | undefined | null = undefined, match = "yyyy-MM-dd") => format(new Date(input || Date.now()), match);

export const addDate = (dateString: string, kvYMD: YMDs, match = "yyyy-MM-dd") => format(add(new Date(dateString), kvYMD), match);

export const monthLastDay = (dateString: string, match = "yyyy-MM-dd") => format(lastDayOfMonth(new Date(dateString)), match);

type Duration = typeof durationInit;
const durationInit = { start: newDate(), end: newDate() };

type YMDs = { years?: number; months?: number; days?: number };
export const dateToObj = (date: string) => ({ year: +date.slice(0, 4), month: +date.slice(5, 7), day: +date.slice(-2) });

const setDuration = makeVar(durationInit);

const useDuration = () => {
  // const [duration, _setDur] = React.useState(durationInit);
  // const setDuration = (part: Partial<Duration>) => _setDur((d) => ({ ...d, ...part }));
  const duration = useReactiveVar(setDuration);
  const dateQuery: DateQueryInput = { before: dateToObj(duration.end), after: dateToObj(duration.start), inclusive: true };

  // const addStart = (duration: YMD) => setStart((d) => _add(d, duration));
  // const addEnd = (duration: YMD) => setEnd((d) => _add(d, duration));
  // const move = (ymd: YMD) => setDuration({ start: addDate(duration.start, ymd), end: addDate(duration.end, ymd) });
  const moveYear = (years: number) => {
    const year = addDate(duration.start, { years });
    setDuration({ start: newDate(year, "yyyy-01-01"), end: newDate(year, "yyyy-12-31") });
  };

  const moveMonth = (months: number) => {
    const month = addDate(duration.start, { months });
    setDuration({ start: newDate(month, "yyyy-MM-01"), end: monthLastDay(month) });
    // setStart((s) => addDate(s, { months: n }, "yyyy-MM-01"));
    // setEnd((e) => MonthLastDay(addDate(e, { months: n }), "yyyy-MM-dd"));
  };
  const moveDay = (days: number) => {
    const day = addDate(duration.start, { days });
    setDuration({ start: day, end: day });
  };

  const start = (match = "yyyy-MM-dd") => newDate(duration.start, match);
  const end = (match = "yyyy-MM-dd") => newDate(duration.end, match);

  const handleSetThisYear = () => setDuration({ start: newDate(null, "yyyy-01-01"), end: newDate(null, "yyyy-12-31") });

  const handleSetThisMonth = () => setDuration({ start: newDate(null, "yyyy-MM-01"), end: monthLastDay(newDate()) });

  const handSetToday = () => setDuration({ start: newDate(), end: newDate() });

  return {
    duration,
    dateQuery,
    start,
    end,
    setDuration,
    moveYear,
    moveMonth,
    moveDay,
    MonthLastDay: monthLastDay,
    handleSetThisYear,
    handleSetThisMonth,
    handSetToday,
  };
};

export default useDuration;
