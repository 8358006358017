import { styled } from "@mui/material";

const DocPaper = styled("div")({
  position: "relative",
  fontFamily: `hwzs,'华文中宋', '宋体'`,
  textAlign: "center",
  color: "#000",
  backgroundColor: "#fff",
  "& h1": {
    fontSize: "20px",
  },
  width: "860px",
  padding: "10px 40px",
  "& img": {
    width: "100%",
  },
});

export default DocPaper;
