import { InputBase, InputBaseProps } from "@mui/material";
import { CSSProperties } from "react";
import React from "react";
// import { Dc, Doc } from "../api/docs/constant-doc";
import numeral from "numeral";

const autoSelectInit = ["-", "null", " ", "0", "/", "0.0"];

type E = <T extends string | number>(
  props: Omit<InputBaseProps, "value" | "onChange" | "onBlue" | "onFocus"> & {
    value: T;
    onInputDone?: (v: T) => void;
    format?: (s: T) => string;
    serialize?: (s: string) => T;
    textAlign?: CSSProperties["textAlign"];
    autoSelect?: string[];
  }
) => JSX.Element;

export type PureInputPropsRest = Omit<Parameters<E>[0], "value" | "onChange">;

const PureInput: E = ({ value, onInputDone: setValue, textAlign, style, format, serialize, autoSelect = autoSelectInit, ...props }) => {
  //显示的字符
  const [str, setStr] = React.useState(`${value}`);
  // console.log("str", str, "value", value);

  //值转显示的字符
  React.useEffect(() => {
    setStr(format ? format(value) : `${value}`);
  }, [value]);

  return (
    <InputBase
      readOnly={!setValue}
      {...props} //
      inputProps={{ style: { ...style, textAlign: textAlign || (typeof value === "string" ? undefined : "right") } }}
      value={str}
      // 焦点事件：自动全选
      onFocus={(e) => {
        if (setValue && autoSelect.includes(e.target.value)) e.target.select();
      }}
      //输入事件：显示字符
      onChange={(e) => setValue && setStr(e.target.value)}
      //模糊事件：赋值
      onBlur={() => {
        if (setValue) {
          if (serialize) return setValue(serialize(str));
          if (typeof value === "number") return setValue(numeral(str).value() as typeof value);
          return setValue((typeof value === "number" ? +str : str) as typeof value);
        }
      }}
    />
  );
};
export default PureInput;
