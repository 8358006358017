import DocTd, { DocTdProps } from "./DocTd";

type T = {
  br?: boolean;
  en?: string;
  zh?: string;
} & DocTdProps;
const DocTdLabel = ({ br, en, zh, ...rest }: T) => {
  return (
    <DocTd {...rest}>
      {en}
      {br && <br />}
      {zh}
    </DocTd>
  );
};

export default DocTdLabel;
