import DocTable from "./components/DocTable";

import { Add } from "@mui/icons-material";
import DocTd from "./components/DocTd";
import DocIconBtn from "./inputs/DocIconBtn";

import DocItemsJobMat from "./DocItemsJobMat";
import React from "react";
import useDocContext from "./useDocContext";
import { jobInit, matInit } from "../v6/docs/constant-excerpt";
import { jobsSummary } from "../utils/jobsSummary";
import { formatAmount } from "../utils/format";

const DocMatList = () => {
  const { imm, disabled } = useDocContext();
  const [doc, imDoc] = imm;
  React.useEffect(() => {
    if (!doc.jobs.length)
      imDoc((d) => {
        d.jobs = [jobInit];
      });
  }, [doc]);
  const sum = jobsSummary(doc.jobs);
  const currency = doc.currency;
  return (
    <DocTable noBorderTop heading="Material List 材料清单">
      <tr>
        {(
          [
            ["5%", "Sn", "编号"],
            ["15%", "code", "编码"],
            ["25%", "description", "描述"],
            ["25%", "label", "材料"],
            ["5%", "Qty", "数量"],
            ["8%", "Unit Price", `单价(${currency})`],
            ["12%", "Materials Cost", `材料费(${currency})`],
          ] as string[][]
        ).map((td, i) => (
          <DocTd key={i} width={td[0]}>
            {td[1]} <br /> {td[2]}
          </DocTd>
        ))}
      </tr>

      {doc.jobs[0]?.mats.map((mat, matId) => (
        <tr key={matId}>
          <DocTd>{matId + 1}</DocTd>
          <DocItemsJobMat full showLabel jobId={0} matId={matId} />
        </tr>
      ))}

      <tr style={{ backgroundColor: "#ddd", height: 40 }}>
        <DocTd colSpan={6}>Sub-total of Material Cost 材料费合计 ( {currency} )</DocTd>
        <DocTd right>
          {disabled || (
            <span style={{ position: "absolute", right: -30 }}>
              <DocIconBtn
                MuiIcon={Add}
                onClick={() =>
                  imDoc((w) => {
                    w.jobs[0].mats.push(matInit);
                  })
                }
              />
            </span>
          )}
          {formatAmount(sum.mat)}
        </DocTd>
      </tr>
    </DocTable>
  );
};

export default DocMatList;
