import React from "react";
import useProjects from "../v6/useProjects";
import useDocs from "../v6/docs/useDocs";
import { Doc } from "../v6/docs/constant-doc";
import { types } from "../v6/docs/constant-title";
import { slugSerialize } from "../v6/docs/constant-slug";

// import useDocs from "../api/docs/useDocs";
// import { Doc } from "../api/docs/constant-doc";

const useProject = (sn: number) => {
  const { projects, appendDoc, updateProject } = useProjects("m", { nameIn: types.map((type) => `${slugSerialize({ sn, type })}`) });
  const project = projects[0]; // { ...projects.find((f) => f.sn === sn), docsArr: [[], [], [], [], [], [], []] as Doc[][] };
  // const inId = project?.dcsArr?.map((dcs) => dcs.map((dc) => dc.id)).flat();
  // const { docs } = useDocs({ in: inId });
  // console.log("docs,...", docs, project.dcsArr);

  // if (project && docs)
  //   project.dcsArr?.forEach((dcs, i1) =>
  //     dcs.forEach((dc, i2) => {
  //       const doc = docs.find((f) => f.id === dc.id) as Doc;
  //       if (doc) project.docsArr[i1][i2] = doc;
  //     })
  //   );
  return { project, appendDoc, updateProject };
};

export default useProject;
