import React from "react";
import { FetchPolicy, gql, useQuery } from "@apollo/client";
import { RecordIdType, RecordQuery } from "../types/graphql";

const queryRecord = gql`
  query Record($idType: RecordIdType = ID, $id: ID = "") {
    record(id: $id, idType: $idType) {
      id
      slug
      excerpt(format: RAW)
    }
  }
`;

const useRecord = (id: string, idType = RecordIdType.Slug, fetchPolicy: FetchPolicy = "network-only") => {
  const { data, refetch } = useQuery<RecordQuery>(queryRecord, {
    variables: { id, idType },
    fetchPolicy,
  });
  // console.log("data", data);

  const record = React.useMemo(
    () => ({
      id: data?.record?.id || "",
      slug: data?.record?.slug || "",
      excerpt: data?.record?.excerpt || "",
    }),
    [data]
  );

  return { record, refetch };
};

export default useRecord;
