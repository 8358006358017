/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Box, Popover } from "@mui/material";
import { BoxProps } from "@mui/system";
import DocIconBtn from "./DocIconBtn";

export type PopProps = { clickClose?: boolean } & BoxProps;

export default function Pop({ children, sx, clickClose, left, ...ps }: PopProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const handleOpen=
  const origin = { vertical: "center", horizontal: (sx as any)?.left ? "left" : "right" } as const;
  return (
    <Box component="span" sx={{ position: "absolute", right: (sx as any)?.left ? undefined : 0, ...sx }} {...ps}>
      <DocIconBtn
        onClick={(e) => setAnchorEl(e.currentTarget)}
        // onFocus={(e) => setAnchorEl(e.currentTarget)}
      />
      <Popover
        elevation={1}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={origin}
        transformOrigin={origin}
        slotProps={{ paper: { sx: { p: 0.5, bgcolor: "#eee", overflowX: "visible", overflowY: "visible" } } }}
        onClick={() => clickClose && setAnchorEl(null)}
      >
        {children}
      </Popover>
    </Box>
  );
}
