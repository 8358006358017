import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Btn, { BtnProps } from "./inputs-pure/Btn";

export type BtnMenuProps = { itemsProps: MenuItemProps[] } & BtnProps;

export default function BtnMenu({ itemsProps, ...ps }: BtnMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    // ps.onClick && ps.onClick(event);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <Btn {...ps} onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {itemsProps.map((itemProps, i) => (
          <MenuItem
            key={i}
            {...itemProps}
            onClick={(e) => {
              itemProps.onClick && itemProps.onClick(e);
              handleClose();
            }}
          />
        ))}
      </Menu>
    </span>
  );
}
