import { Stack } from "@mui/material";
import React from "react";

const DocDoubleTb = ({ pos = 70, gutter = 1, minHeight = 230, left = <></>, right = <></> }) => {
  return (
    <Stack pt={1} direction="row" justifyContent="space-between">
      <div style={{ width: `${pos - gutter / 2}%`, minHeight }}>{left}</div>
      <div style={{ width: `${100 - pos - gutter / 2}%`, minHeight }}>{right}</div>
    </Stack>
  );
};

export default DocDoubleTb;
