import objectEntries from "../../utils/objectEntries";

import { Title, titleInit, titleParse, titleSerialize } from "./constant-title";
import { Excerpt, excerptInit, excerptParse, excerptSerialize } from "./constant-excerpt";
import { newDate } from "../../utils/useDuration";
import { ApiDoc, DocSize } from "../useApiDocs";
import { CreateDocInput, PostStatusEnum, UpdateDocInput } from "../../api/types/graphql";
import { slugInit, slugSerialize } from "./constant-slug";

const s = { id: "", ...titleInit };
const m = { ...s, sn: 0, ...excerptInit };
const f = { ...m, content: "", status: PostStatusEnum.Draft, date: newDate() };
export const docInit = { s, m, f };
export type Doc<S extends DocSize = "f"> = (typeof docInit)[S];

const sDocParse = (apiDoc: ApiDoc<"s">): Doc<"s"> => ({
  id: apiDoc.id,
  ...titleParse(apiDoc.title),
});
const mDocParse = (apiDoc: ApiDoc<"m">): Doc<"m"> => ({
  ...sDocParse(apiDoc),
  ...excerptParse(apiDoc.excerpt),
});
const fDocParse = (apiDoc: ApiDoc<"f">): Doc<"f"> => ({
  ...mDocParse(apiDoc),
  content: apiDoc.content,
  status: apiDoc.status,
  date: apiDoc.date,
});

export const docParse = <Size extends DocSize>(apiDoc: ApiDoc<Size>): Doc<Size> => {
  if ((apiDoc as any).excerpt !== undefined) return mDocParse(apiDoc as any) as any;
  if ((apiDoc as any).content !== undefined) return fDocParse(apiDoc as any) as any;
  return sDocParse(apiDoc) as any;
};

export const docCreateInput = (part: Partial<Doc>): CreateDocInput => {
  let titleObj: Partial<Title> = {};
  let excerptObj: Partial<Excerpt> = {};
  objectEntries(part as Doc).forEach(([k, v], i) => {
    if (Object.keys(titleInit).includes(k)) titleObj = { ...titleObj, [k]: v };
    if (Object.keys(excerptInit).includes(k)) excerptObj = { ...excerptObj, [k]: v };
  });
  const { date, status } = part;
  const slug = slugSerialize({ ...slugInit, ...part });
  const title = titleSerialize(titleObj);
  const excerpt = excerptSerialize(excerptObj);
  const content = "";
  return { date, slug, title, excerpt, content, status };
};

export const docUpdateInput = (post: Partial<Doc> & { id: string }): UpdateDocInput => {
  return {
    id: post.id,
    ...docCreateInput(post),
  };
};
