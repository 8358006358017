import { sr } from "date-fns/locale";
import numeral from "numeral";

export const serializeTitleNode = (str?: string | number) => {
  if (str === undefined) return "";
  if (typeof str === "number") return `${str.toFixed(0)}`;
  if (str === " ") return "";
  return str;
};
export const serializeSn = (sn?: number) => {
  return (sn || 0).toString().padStart(6, "0");
};
export const formatModel = (str: string) => {
  return str.toUpperCase();
};

export const formatAmount = (a: any, format = "0,0.[00]", zero = "-") => {
  const n = numeral(a);
  return n.value() ? n.format(format) : zero;
};

export const serializeAmount = (str: any) => numeral(str).value() || 0;

export const formatPercent = (n: any, format = "0,0.[000]%", zero = "") => formatAmount(n, format, zero);

export const formatPlate = (str: string, space = true) => {
  if (!str) return "";
  // str = str.replaceAll("_", " ");
  const match = str.match(/[0-9]+/);
  if (match && match.length) {
    const num = match[0];
    return str.replace(num, `${space ? " " : ""}${num}`).toUpperCase();
  }
  return str.toUpperCase();
};

export const formatMileage = (v: string | number) => `${numeral(v).format("0,0")} /km`;
export const serializeMilage = (v: string) => {
  let str = v;
  const arr = v.split(" ");
  if (arr.length) str = arr[0];
  return serializeAmount(str);
};

export const formatTel = (str: string) => {
  const pure = str.replaceAll(" ", "").replaceAll("-", "");
  return `${pure.slice(0, 3)}-${pure.slice(3)}`;
};
