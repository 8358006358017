/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Box, Popover, PopoverOrigin } from "@mui/material";
import { BoxProps } from "@mui/system";
import Btn, { BtnProps } from "./inputs-pure/Btn";

export type PadProps = { clickClose?: boolean; origin?: PopoverOrigin } & BtnProps;

export default function BtnPad({ children, sx, clickClose, origin, ...ps }: PadProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const handleOpen=
  return (
    <Box component="span" {...ps}>
      <Btn
        {...ps}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        // onFocus={(e) => setAnchorEl(e.currentTarget)}
      />
      <Popover
        elevation={1}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        // onKeyDown={(e) => {
        //   ["Enter", "Tab"].includes(e.key) && Boolean(anchorEl) && setAnchorEl(null);
        //   console.log("in", anchorEl);
        // }}
        anchorOrigin={origin}
        transformOrigin={origin}
        PaperProps={{ sx: { p: 0, bgcolor: "#eee", overflowX: "visible", overflowY: "visible" } }}
        // sx={{ minHeight: 0 }}
        onClick={() => clickClose && setAnchorEl(null)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            setAnchorEl(null);
            // e.target.b();
          }
        }}
      >
        {children}
      </Popover>
    </Box>
  );
}
