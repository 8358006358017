import React from "react";
import useRecord from "../api/record/useRecord";
import useVar from "../utils/useVar";
import { ReactiveVar, useReactiveVar } from "@apollo/client";
import { RecordIdType } from "../api/types/graphql";
import useRecordMutation from "../api/record/useRecordMut";
import jsonParse from "../utils/jsonParse";
import { DraftFunction, Updater, useImmer } from "use-immer";
import { produce } from "immer";

function useExcerpt<T>(varExcerpt: ReactiveVar<T>, varEdited: ReactiveVar<boolean>, slug: string) {
  const excerpt = useReactiveVar<T>(varExcerpt);
  const edited = useReactiveVar(varEdited);
  const setExcerpt = (value: T) => {
    varExcerpt(value);
    varEdited(true);
  };
  const imExcerpt = (draftFunc: DraftFunction<T>) => {
    varExcerpt(
      produce(excerpt, (draft) => {
        draftFunc(draft);
      })
    );
    varEdited(true);
  };
  const imm = [excerpt, imExcerpt];
  const state = [excerpt, setExcerpt];
  const { record, refetch } = useRecord(slug, RecordIdType.Slug, "network-only");
  React.useEffect(() => {
    varExcerpt(jsonParse(record.excerpt));
    varEdited(false);
  }, [record]);
  const { update: _update } = useRecordMutation(refetch);
  const update = () => {
    _update({ id: record.id, excerpt: JSON.stringify(excerpt) });
    varEdited(false);
  };

  return { excerpt, setExcerpt, imExcerpt, imm, state, edited, setEdited: varEdited, update };
}

export default useExcerpt;
