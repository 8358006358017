import DocTable from "./components/DocTable";

import { Add } from "@mui/icons-material";
import DocTd from "./components/DocTd";
import DocIconBtn from "./inputs/DocIconBtn";

import DocItemsJob from "./DocItemsJob";
import DocItemsJobMat from "./DocItemsJobMat";
import useDocContext from "./useDocContext";
import { jobsSummary } from "../utils/jobsSummary";
import { formatAmount } from "../utils/format";
import { excerptInit, jobInit, matInit } from "../v6/docs/constant-excerpt";

type P = { full?: boolean };
const DocItems = ({ full }: P) => {
  const {
    imm: [doc, imDoc],
    disabled,
  } = useDocContext();
  // console.log("DocItems doc", doc);

  const sum = jobsSummary(doc.jobs);
  const jobRowSpans = doc.jobs.map((j) => j.mats.length || 1);
  const MoneyIcon = { USD: "$", CNY: "￥", ZMK: "K" }[doc.currency || 0];
  const jobCostRowSpans = doc.jobs.map((job, jobId, jobs) => {
    let jobCostRowSpan = jobRowSpans[jobId] as number;
    if (jobId > 0 && job.follow) {
      jobCostRowSpan = 0;
    } else {
      for (let i = jobId + 1; i < jobs.length; i++) {
        if (jobs[i].follow) jobCostRowSpan += jobRowSpans[i];
        else break;
      }
    }
    return jobCostRowSpan;
  });
  const BtnAppendJob = disabled || (
    <span style={{ position: "absolute", left: -30 }}>
      <DocIconBtn
        MuiIcon={Add}
        onClick={() =>
          imDoc((w) => {
            w.jobs.push({ ...jobInit, mats: [matInit] });
          })
        }
      />
    </span>
  );
  return (
    <>
      <DocTable noBorderTop heading="Maintenance Items and Expense List 维修项目及费用清单">
        <tr>
          {(
            [
              ["5%", "Sn", "编号"],
              ["10%", "Item Code", "项目编号"],
              ["33%", "Maintenance Item", "维修项目"],
              ["9%", "Labor Cost", full ? `人工费(${MoneyIcon})` : "耗费/建议工时"],
              ["19%", "Used Parts/Materials", "所用配件/材料"],
              ["5%", "Qty", "数量"],
              full && ["8%", "Unit Price", `单价(${MoneyIcon})`],
              full && ["12%", "Materials Cost", `材料费(${MoneyIcon})`],
            ] as string[][]
          )
            .filter((f) => f)
            .map((td, i) => (
              <DocTd key={i} width={td[0]}>
                {td[1]} <br /> {td[2]}
              </DocTd>
            ))}
        </tr>

        {doc.jobs?.map((job, jobId) => {
          //
          const rowSpan = jobRowSpans[jobId];
          const tdsLabor = <DocItemsJob jobId={jobId} rowSpan={rowSpan} costRowSpan={jobCostRowSpans[jobId]} />;

          return job.mats.length === 0 ? (
            <tr key={jobId}>
              {tdsLabor}
              <td />
              {full && <td />}
              {full && <td />}
              <DocTd>
                {disabled || (
                  <span style={{ position: "absolute", right: -30 }}>
                    <DocIconBtn
                      MuiIcon={Add}
                      onClick={() =>
                        imDoc((w) => {
                          w.jobs[jobId].mats.push(matInit);
                        })
                      }
                    />
                  </span>
                )}
              </DocTd>
            </tr>
          ) : (
            job.mats.map((mat, matId) => (
              <tr key={`${jobId}-${matId}`}>
                {matId === 0 && tdsLabor}
                <DocItemsJobMat full={full} jobId={jobId} matId={matId} />
              </tr>
            ))
          );
        })}
        {full && (
          <tr style={{ backgroundColor: "#ddd", height: 40 }}>
            <DocTd colSpan={3}>
              Sub-total of Labor Cost 人工费合计 ( {MoneyIcon} ){BtnAppendJob}
            </DocTd>
            <DocTd right>{formatAmount(sum.job, "0,0.[00]", "0")}</DocTd>
            <DocTd colSpan={3}>Sub-total of Material Cost 材料费合计 ( {MoneyIcon} )</DocTd>
            <DocTd right>{formatAmount(sum.mat, "0,0.[00]", "0")}</DocTd>
          </tr>
        )}
      </DocTable>
      {full || <div style={{ position: "relative" }}> {BtnAppendJob}</div>}
    </>
  );
};
export default DocItems;
