import React from "react";
import AuthProvider from "./api/auth/AuthProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ApiProvider from "./api/ApiProvider";
import PageIndex from "./pages/PageIndex";
import PageLogin from "./pages/PageLogin";
import PageProjects from "./pages/admin/PageProjects";
import { RouteAuth } from "./api/auth/RouteAuth";
import MuiProvider from "./layout/MuiProvider";
import PageNoMatch from "./pages/PageNoMatch";
// import PageProjectDoc from "./pages/PageProjectDoc";
import PageRefresh from "./pages/PageRefresh";
import PageDoc from "./pages/admin/PageDoc";
import PagePayrolls from "./pages/payroll/PagePayrolls";
import PagePayroll from "./pages/payroll/PagePayroll";
import PageEmployees from "./pages/payroll/PageEmployeeList";
import PageJournal from "./pages/journal/PageJournal";
import PageTemplates from "./pages/admin/PageTemplates";
import PageTemplate from "./pages/admin/PageTemplate";
import PageTest from "./test/PageTest";
import PageCopyField from "./pages/setting/PageCopyField";
import PagePayrollSlips from "./pages/payroll/PagePayrollSlips";
import PageLabors from "./pages/payroll/PageLabors";
import PageLabor from "./pages/payroll/PageLabor";
// import PageProject from "./pages/PageProject";
// import PageSetting from "./setting/PageSetting";

function App() {
  return (
    <ApiProvider>
      <BrowserRouter>
        <AuthProvider pathLogin="/login" pathHome="/projects">
          <MuiProvider>
            <Routes>
              <Route index element={<RouteAuth children={<PageProjects />} />} />
              <Route path="/index" element={<PageIndex />} />
              <Route path="/login" element={<PageLogin />} />
              <Route path="/projects" element={<RouteAuth children={<PageProjects />} />} />
              {/* <Route path="/setting" element={<RouteAuth children={<PageSetting />} />} /> */}
              <Route path="/document/:slug" element={<RouteAuth children={<PageDoc />} />} />

              <Route path="/templates" element={<RouteAuth children={<PageTemplates />} />} />
              <Route path="/template/:slug" element={<RouteAuth children={<PageTemplate />} />} />

              {/* <Route path="/project/:sn/:id" element={<RouteAuth children={<PageProject />} />} /> */}

              <Route path="/labors" element={<RouteAuth children={<PageLabors />} />} />
              <Route path="/labor/:slug" element={<RouteAuth children={<PageLabor />} />} />

              <Route path="/payrolls" element={<RouteAuth children={<PagePayrolls />} />} />
              <Route path="/payroll/:slug" element={<RouteAuth children={<PagePayroll />} />} />
              <Route path="/payroll/:slug/slips" element={<RouteAuth children={<PagePayrollSlips />} />} />
              
              <Route path="/employee-list" element={<RouteAuth children={<PageEmployees />} />} />

              <Route path="/journal/:date" element={<RouteAuth children={<PageJournal />} />} />

              <Route path="*" element={<PageNoMatch />} />
              <Route path="/refresh" element={<PageRefresh />} />

              <Route path="/test" element={<RouteAuth children={<PageTest />} />} />

              <Route path="/setting/copy-field" element={<RouteAuth children={<PageCopyField />} users={["max"]} />} />
            </Routes>
          </MuiProvider>
        </AuthProvider>
      </BrowserRouter>
    </ApiProvider>
  );
}

export default App;
