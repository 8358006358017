/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */

import { ReactNode } from "react";
import { styled } from "@mui/material";

const fontSize = 12;
const borderSpacing = 0;
const padding = 0;
const margin = 0;
const lineHeight = 1.1;
const fontMix = { fontFamily: `'华文中宋', '华文宋体', '宋体'`, fontSize, lineHeight, textAlign: "center" };

const StyledTable = styled("table")({
  fontSize,
  textAlign: "left",
  width: "100%",
  height: "100%",
  borderSpacing,
  borderCollapse: "collapse",
  padding,
  lineHeight,
  "& thead": {
    fontSize,
    backgroundColor: "#bbb",
    "& th": {
      border: "0.3px solid #000",
      textAlign: "left",
      padding: "4px 6px",
    },
  },
  "& td": {
    margin,
    border: "0.3px solid #000",
    "& div": {
      width: "100%",
      height: "100%",
      // minHeight: "30px",
      ...fontMix,
      boxSizing: "border-box",
      border: 0,
      padding: "2px",
      letterSpacing: "-0.5px",
      caretColor: "#666",
      // pageBreakBefore: "always !important",
      pageBreakInside: "avoid !important",
      overflow: "visible !important",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      // "& fieldset": {
      //   display: "none",
      // },
    },
  },
  "& .MuiInputBase-input": { ...fontMix },
});

export type DocTableProps = {
  heading?: ReactNode;
  noBorderTop?: boolean;
} & React.ComponentProps<"div">;
const DocTable = ({ heading, children, noBorderTop, ...props }: DocTableProps) => (
  <div {...props} style={{ position: "relative", ...props.style }}>
    <StyledTable>
      {heading && (
        <thead>
          <tr>
            <th colSpan={99} style={{ borderTop: noBorderTop ? 0 : undefined }}>
              <div>{heading}</div>
            </th>
          </tr>
        </thead>
      )}
      <tbody>{children}</tbody>
    </StyledTable>
  </div>
);

export default DocTable;
