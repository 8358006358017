import { Close, Delete, FileCopy, MapsHomeWork, Publish } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { ReactNode, useState } from "react";
import Dlg from "./Dlg";
import Btn, { BtnProps } from "./inputs-pure/Btn";
import Input from "./Input";
import Pick from "./Pick";
import { Type, types_en } from "../v6/docs/constant-title";

type P = {
  title: string;
  btnProps: BtnProps[];
} & BtnProps;
const BtnDlg = ({ title, children, onClick, btnProps, ...ps }: P) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const btnAddFunc = (Btn: JSX.Element) => Btn.props.onClick;
  return (
    <>
      <Btn onClick={onClick || handleOpen} {...ps} />
      <Dlg
        title={title}
        maxWidth="lg"
        open={open}
        btnProps={[
          ...btnProps.map((bp) => ({ ...bp, onMouseUp: handleClose })),
          { onClick: handleClose, startIcon: <Close fontSize="small" />, label: "cancel" },
        ]}
      >
        {children}
      </Dlg>
    </>
  );
};

export const BtnDlgDelete = ({ onClick, ...ps }: Omit<P, "title" | "btnProps">) => (
  <BtnDlg color="error" label="del" startIcon={<Delete />} title="Delete?" btnProps={[{ color: "error", label: "Delete", onClick }]} {...ps} />
);
export const BtnDlgSubmit = ({ onClick, ...ps }: Omit<P, "title" | "btnProps">) => (
  <BtnDlg color="inherit" label="submit" startIcon={<Publish />} title="Submit 提交" btnProps={[{ color: "error", label: "Submit", onClick }]} {...ps} />
);
export const BtnDlgTemplate = ({ onClick, ...ps }: Omit<P, "title" | "btnProps">) => (
  <BtnDlg
    color="secondary"
    label="template"
    startIcon={<MapsHomeWork />}
    title="To be template 设置为模版"
    btnProps={[{ color: "error", label: "To be Template", onClick }]}
    {...ps}
  />
);
export const BtnDlgClone = ({ onClone, ...ps }: Omit<P, "title" | "btnProps"> & { onClone: (type: Type) => any }) => {
  const [type, SetType] = useState<Type>("b");
  return (
    <BtnDlg
      color="info"
      label="Clone"
      startIcon={<FileCopy />}
      title="Clone 复制"
      btnProps={[{ color: "info", children: "Clone", onClick: () => onClone(type) }]}
      {...ps}
    >
      <Pick
        label="Doc type"
        value={type}
        onChange={(e) => SetType(e.target.value as Type)}
        itemProps={types_en.map((t) => ({ children: t, value: t.slice(0, 1) }))}
      />
    </BtnDlg>
  );
};
// export const BtnWithDlgArchive = ({
//   onArchive,
//   ...ps
// }: Omit<P, "title" | "btnProps"> & { onArchive: (doc: number) => void }) => {
//   const [proId, SetProId] = useState(0);
//   return (
//     <BtnDlg
//       color="info"
//       label="Archive"
//       startIcon={<FileCopy />}
//       title="Archive 归档"
//       btnProps={[{ color: "info", label: "Archive", onClick: () => onArchive(proId) }]}
//       {...ps}
//     >
//       <Input value={proId} onChange={(e) => SetProId(+e.target.value)} />
//     </BtnDlg>
//   );
// };
export default BtnDlg;
