import React from "react";
import { Link } from "react-router-dom";

const PageIndex = () => {
  return (
    <div>
      PageIndex
      <div>
        <Link to="/projects">projects</Link>
      </div>
      <div>
        <Link to="/templates">templates</Link>
      </div>
      <div>
        <Link to="/setting/copy-field">setting / copy-field</Link>
      </div>
      <div>
        <Link to="/employee-list">employees</Link>
      </div>
      <div>
        <Link to="/payrolls">payrolls</Link> - <Link to="/labors">labors</Link>
      </div>
      <div>
        <Link to="/journal/2023-07-07">journal</Link>
      </div>
    </div>
  );
};

export default PageIndex;
