import React from "react";
import { Team, teams } from "../v6/docs/constant-title";

const useTeam = () => {
  const [teamId, setTeamId] = React.useState<number>(-1);
  const team = (teamStr?: Team) => {
    if (teamStr) setTeamId(teams.indexOf(teamStr));
    else return teamId === -1 ? "all" : teams[teamId];
  };
  return { team, teamId, setTeamId };
};

export default useTeam;
