import styled from "@emotion/styled";
import PureTable from "./PureTable";
import { formatAmount } from "../../utils/format";
import { Stack } from "@mui/material";
import { monthLastDay, newDate } from "../../utils/useDuration";

const TableWrap = styled("div")`
  th {
    font-size: 12px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    td,
    th {
      padding: 0 5px;
      border: 1px solid black;
      div {
        display: flex;
        align-items: center;
        height: 30px;
      }
    }
  }
  .line1 {
    td:nth-child(6),
    th:nth-child(6) {
      background-color: #f2f085;
    }
    td:nth-child(11),
    th:nth-child(11) {
      background-color: #ecccac;
    }
  }
  .line2 {
    th {
      border-top: 0;
    }
    td:nth-child(4),
    th:nth-child(4) {
      background-color: #cfecb2;
    }
    td:nth-child(7),
    th:nth-child(7) {
      background-color: #add1ee;
    }
  }
`;

type F = <K extends string>(props: {
  slug: string; //
  line1Keys: readonly K[];
  line2Keys: readonly K[];
  row: { [k in K]: any };
  fullname: string;
}) => JSX.Element;
const SalarySlip: F = ({ slug, line1Keys, line2Keys, row, fullname }) => {
  return (
    <TableWrap>
      <div className="no-break-inside" style={{ borderBottom: "1px solid rgb(255, 144, 144)" }}>
        <h5 style={{ padding: "0 20px", margin: "0 10rem 0.5rem", textAlign: "center", backgroundColor: "#d8ebfb" }}>Pay Statement of Tulando for {slug}</h5>
        <Stack direction="row" justifyContent="space-between">
          <small>Full Name: {fullname}</small>
          <small>
            Period: {newDate(`${slug}-01`, "dd/MM/yyyy")} to {monthLastDay(`${slug}-01`, "dd/MM/yyyy")}
          </small>
          <small>Payment Date: {newDate(null, "dd/MM/yyyy")}</small>
        </Stack>
        <div className="line1">
          <PureTable keys={line1Keys} rows={[row]} render={(r, k) => <div style={{ justifyContent: "center" }}>{formatAmount(row[k])}</div>} />
        </div>
        <div className="line2">
          <PureTable keys={line2Keys} rows={[row]} render={(r, k) => <div style={{ justifyContent: "center" }}>{formatAmount(row[k])}</div>} />
        </div>
        {/* <Table>
          <thead>
            <tr>
              <th>Sn. </th>
              <th>Full Name</th>
              {payroll.colHeaders.map((h, i) => (
                <th key={i} children={h} />
              ))}
              <th>Confirmed by Signature</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th />
              <th>{name}</th>
              {cells.map((cell, c) => (
                <td key={c} children={formatAmount(cell)} />
              ))}
              <td />
            </tr>
          </tbody>
        </Table> */}
        <Stack direction="row" justifyContent="space-between" pr={15} py={3}>
          <small>Received by:</small>
          <small>Date:</small>
        </Stack>
      </div>
      {/* <h3 style={{ textAlign: "center" }}>
        <i>EMPLOYEE PAYROLL OF TULANDO {slug}</i>
      </h3>
      <Table>
        <PureTable
          keys={keys}
          rows={rows}
          render={(r, k) => {
            if (k === "sn.") return <div style={{ textAlign: "center" }}> {r + 1}</div>;
            if (k === "name") return <div style={{ textAlign: "center" }}> {rows[r][k]}</div>;
            if (k === "Confirmed by Signature") return "";
            return <div style={{ textAlign: "right" }}>{formatAmount(rows[r][k])}</div>;
          }}
        />
      </Table>
      <small>Remark: Lunch Provided</small> */}
    </TableWrap>
  );
};

export default SalarySlip;
