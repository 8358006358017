import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import { DocContextProvider } from "../../docPrint/useDocContext";
import Toolbar from "../../layout/Toolbar";
import { BtnDlgClone, BtnDlgDelete, BtnDlgSubmit, BtnDlgTemplate } from "../../components/BtnDlg";
import { BtnPrint, BtnSave } from "../../components/inputs-pure/Btn";
import { Box, Divider } from "@mui/material";
import DocInput from "../../docPrint/inputs/DocInput";
import ToolbarNavigate from "../../layout/ToolbarNavigate";
import DocPaper from "../../docPrint/components/DocPaper";
import DocHeader from "../../docPrint/DocHeader";
import DocSn from "../../docPrint/DocSnDate";
import DocVehicle from "../../docPrint/DocVehicle";
import DocRequest from "../../docPrint/DocRequest";
import Doc2Notice from "../../docPrint/Doc2Notice";
import DocFooter from "../../docPrint/DocFooter";
import DocItems from "../../docPrint/DocItems";
import Doc1Notice from "../../docPrint/Doc1Notice";
import DocBill from "../../docPrint/DocBill";
import Doc5Notice from "../../docPrint/Doc5Notice";
import DocDoubleTb from "../../docPrint/components/DocDoubleTb";
import DocMatList from "../../docPrint/DocMatList";
import DocsBtns from "../../post/DocsBtns";
import objectFill from "../../utils/objectFill";
import Chip from "../../components/Chip";
import { formatMileage, formatModel, formatPlate } from "../../utils/format";
import { useHotkeys } from "react-hotkeys-hook";
import useTemplates from "../../hooks/useTemplates";
import DocRequestDisabled from "../../docPrint/DocRequestDisabled";
import useDoc from "../../v6/docs/useDoc";
import useProject from "../../hooks/useProject";
import { vehicleInit } from "../../v6/docs/constant-excerpt";
import { PostStatusEnum } from "../../api/types/graphql";
import { docInit } from "../../v6/docs/constant-doc";

const PageDoc = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { imm, doc, remove, update, edited } = useDoc(slug || "");

  const { type, sn } = doc ;
  const { project, appendDoc, updateProject } = useProject(sn);

  const options = useTemplates(doc.model);
  // console.log("project", project);

  // const options = useOptions();
  // if (loading) return <>loading</>;
  const showHeader = imm[0].showHeader;
  // return <>123</>
  // console.log("doc", doc);

  return (
    <Layout
      title={sn ? `${type}${sn}-${doc.owner || doc.vip}-${formatModel(doc.model)}-${formatPlate(doc.plate, false)}` : "Tulando System Document"}
      alignItems="center"
    >
      {/* <Loading open={lp || ls} /> */}
      <DocContextProvider imm={imm} disabled={false} options={options}>
        <Toolbar>
          <ToolbarNavigate warning={edited} />
          {project && (
            <DocsBtns
              atType={type}
              docsArr={project.docsArr}
              disabled={edited}
              clickUrl={navigate}
              clickAppend={(type) => appendDoc({ type, sn, ...objectFill(vehicleInit, doc) }).then((res) => navigate(`/document/${res.slug}`))}
            />
          )}
          <Box flexGrow={1} />
          <BtnDlgDelete onClick={() => remove().then(() => navigate("/admin"))} />
          <BtnDlgSubmit onClick={() => update({ ...doc, status: PostStatusEnum.Private })} disabled={doc.status === PostStatusEnum.Private} />
          <BtnDlgTemplate
            onClick={
              () => update({ ...doc, status: PostStatusEnum.Publish })
              // imm[1]((d) => {
              //   d.statusId = 2;
              // })
            }
            disabled={doc.status === PostStatusEnum.Publish}
          />
          <BtnDlgClone onClone={(type) => appendDoc({ ...doc, type, status: PostStatusEnum.Draft }).then((res) => navigate(`/document/${res.slug}`))} />
          <BtnPrint onClick={window.print} />
          <BtnSave onClick={() => update()} disabled={!edited} />
        </Toolbar>
        <Toolbar>
          <small style={{ color: "#666", padding: "0 5px" }}>note:</small>
          <DocInput fullWidth multiline field="note" />
        </Toolbar>

        <Divider className="no-print" sx={{ width: "100%" }} />

        {(type === "q" || type === "b") && (
          <>
            <Toolbar>
              <small style={{ color: "#666", padding: "0 5px" }}>pick vehicle:</small>
              {project?.docsArr[0].length &&
                project.docsArr[0].map((d, i) => (
                  <Chip
                    key={i}
                    onClick={() => imm[1]({ ...doc, ...(d && objectFill(vehicleInit, d)) })}
                    label={`${formatPlate(d.plate, false)} , ${formatModel(d.model)} , ${formatMileage(d.mileage)}`}
                  />
                ))}
            </Toolbar>
            <Toolbar>
              <small style={{ color: "#666", padding: "0 5px" }}>pick Requests:</small>
              <span>
                {project?.docsArr[0].length &&
                  project.docsArr[0]
                    .map((d) => d.requests)
                    .flat()
                    .map(
                      (r, i) =>
                        r && (
                          <Chip
                            key={i}
                            onClick={() =>
                              imm[1]((d) => {
                                d.requests?.push(r);
                              })
                            }
                            label={r}
                          />
                        )
                    )}
              </span>
            </Toolbar>
            <Divider className="no-print" sx={{ width: "100%" }} />
          </>
        )}

        <DocPaper>
          {type === "r" && (
            <>
              <DocHeader heading="Reception Form of TASC 接车单" />
              <DocSn />
              <DocVehicle detail />
              <DocRequest pick />
              <Doc2Notice />
              <DocFooter left="Receptionist 接待员:" right="Customer 客户:" company={showHeader} />
            </>
          )}
          {type === "c" && (
            <>
              <DocHeader heading="Checkcard" />
              <DocSn />
              <DocVehicle />
              <DocRequestDisabled requests={project?.docsArr[0].length ? project.docsArr[0][0].requests : []} />
              <DocRequest advice />
              <DocItems />
              <DocFooter left="Group 组：" right="Supervisor 车间:" company={showHeader} />
            </>
          )}
          {type === "q" && (
            <>
              <DocHeader heading="Maintenance Advice and Quotation <br> 维修建议及费用报价单" />
              <DocSn />
              <DocVehicle owner />
              <DocRequest />
              <DocItems full />
              <DocDoubleTb left={<Doc1Notice />} right={<DocBill />} />
              <DocFooter left="" right="" company={showHeader} />
            </>
          )}
          {type === "j" && (
            <>
              <DocHeader heading="Jobcard" />
              <DocSn />
              <DocVehicle />
              <DocRequestDisabled requests={project?.docsArr[0].length ? project.docsArr[0][0].requests : []} />
              <DocRequest advice />
              <DocItems />
              <DocFooter left="Group 组：" right="Supervisor 车间:" company={showHeader} />
            </>
          )}
          {type === "m" && (
            <>
              <DocHeader heading="Material card" />
              <DocSn />
              <DocVehicle />
              <DocMatList />
              <DocFooter left="" right="" company={showHeader} />
            </>
          )}
          {type === "b" && (
            <>
              <DocHeader heading={showHeader ? "Maintenance Statement" : "Proforma Invoice"} />
              <DocSn />
              <DocVehicle owner />
              <DocRequest />
              <DocItems full />
              <DocDoubleTb left={<Doc5Notice />} right={<DocBill />} />
              <DocFooter left="" right="" company={showHeader} />
            </>
          )}
        </DocPaper>
      </DocContextProvider>
    </Layout>
  );
};

export default PageDoc;
