import React from "react";
import { deduplicateObj, deduplicateVar } from "../utils/deduplicate";
import objectFill from "../utils/objectFill";

import similarity from "similarity";
import { serializeTitleNode } from "../utils/format";
import { Job, Mat, Owner, jobInit, ownerInit, vehicle, vehicleInit } from "../v6/docs/constant-excerpt";
import useDocs from "../v6/docs/useDocs";
import { PostStatusEnum } from "../api/types/graphql";

export type Template = typeof templateInit;
export const templateInit = {
  id: "",
  sn: 0,
  model: "",
  request: "",
  jobs: [jobInit],
};

export type OptionsArr = typeof optionsArrInit;
export const optionsArrInit = {
  vehicles: [] as vehicle[],
  owners: [] as Owner[],
  jobs: [] as Job[],
  materials: [] as Mat[],
  requests: [] as string[],
  templates: [] as Template[],
};

const useTemplates = (model?: string) => {
  const { docs: _docs } = useDocs({ m: { status: PostStatusEnum.Publish } }, "cache-and-network");
  // const ids = docs.filter((f) => f.statusId > 0 || f.sn < 100).map((d) => d.id);
  // const { docs: _docs } = useDocs({ in: ids }, "cache-and-network");
  // console.log("option docs 1", _docs);
  const docs = model
    ? _docs.m.sort((d1, d2) => {
        const m = serializeTitleNode(model);
        return similarity(d2.model, m) - similarity(d1.model, m);
      })
    : _docs.m;
  // console.log("option docs 2", docs);

  const res = React.useMemo(() => {
    const vehicles: OptionsArr["vehicles"] = docs.map((p) => objectFill(vehicleInit, p));
    const owners = docs.map((p) => objectFill(ownerInit, p));
    const jobs = docs.flatMap((f) => f.jobs);
    const materials = jobs.flatMap((j) => j.mats);
    const requests = deduplicateVar(docs.flatMap((p) => p.requests));

    const templates: Template[] = docs.map((d) => ({
      id: d.id,
      sn: d.sn,
      request: d.requests.length === 1 ? d.requests[0] : "",
      model: d.model,
      jobs: d.jobs,
    }));

    const optionsArr: OptionsArr = { vehicles, owners, jobs, materials, requests, templates };
    // console.log("vehicles", vehicles);
    // console.log("options", options.vehicles);
    // const ops = <K extends "vehicles" | "owners" | "jobs" | "materials", F extends keyof OptionsArr[K]>(key: K, field: F) => {
    //   return deduplicateVar(optionsArr[key].map((v) => (v as any)[field])).filter((f) => f && [" ", "_", "0", "-"].includes(f));
    // };
    return optionsArr;
  }, [docs, model]);
  return res;
};

export default useTemplates;
