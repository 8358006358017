import React from "react";
import useAuth from "../../api/auth/useAuth";

import XData from "../../layout/XData";
// import { DOCUMENT_TYPES, DOCUMENT_TYPES_ZH, TEAMS, TEAMS_ZH, postInitMaker } from "../base/_constant";
import Btn, { BtnProps } from "../../components/inputs-pure/Btn";
import { Stack } from "@mui/material";
import Layout from "../../layout/Layout";

import { useNavigate } from "react-router-dom";

import BtnToggle from "../../components/BtnToggle";
import useDuration, { newDate } from "../../utils/useDuration";
import BtnPad from "../../components/BtnPad";
import Input from "../../components/Input";
import useProjects, { Project, projectInit } from "../../v6/useProjects";
import { useImmer } from "use-immer";
// import BtnDlgProject, { DlgProjectForm } from "../post/BtnDlgProject";
// import { excerptInit } from "../api/constant-excerpt";
import objectEntries from "../../utils/objectEntries";
// import DocsBtns2 from "../post/DocsBtns";

import BtnDlgProject from "../../post/BtnDlgProject";
import DocsBtns from "../../post/DocsBtns";
import useTeam from "../../hooks/useTeam";
import DataGrid from "../../components/data-display/DataGrid";
import { teams, teams_zh } from "../../v6/docs/constant-title";
// import BtnDlgProject, { DlgProjectForm } from "../post/BtnDlgProject";

const PageProjects = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const dur = useDuration();
  const { team, teamId, setTeamId } = useTeam();
  const { projects, appendDoc, newSn, appendProject, updateProject } = useProjects("s"); //, newSlug, appendProject, appendDocument, dur, team
  const rows = projects.filter((f) => f.date >= dur.start() && f.date <= dur.end()).filter((f) => teamId === -1 || team() === f.team);

  const handleAppendProject = (project: Project<"s">) => appendProject({ ...project, id: undefined });
  return (
    <Layout title="Tulando Projects">
      <DataGrid
        columns={[
          {
            field: "sn",
            width: 90,
            renderCell: ({ value, row, rowNode }) =>
              rowNode.type !== "pinnedRow" ? (
                <BtnDlgProject
                  title={`Update Project ${row.sn}`}
                  label={value}
                  defaultValue={row}
                  onConfirm={(form) => {
                    updateProject({ ...row, ...form });
                  }}
                />
              ) : (
                <BtnDlgProject
                  title={`New Project ${newSn}`}
                  label={`${value} new`}
                  defaultValue={{ ...projectInit, sn: newSn, date: newDate() }}
                  onConfirm={handleAppendProject}
                />
              ),
          },
          {
            field: "date",
            renderCell: (pa) =>
              pa.rowNode.type === "pinnedRow" ? (
                <Stack direction="row" spacing={0.2}>
                  <BtnToggle selected={dur.start("yyyy") === newDate(null, "yyyy")} onChange={dur.handleSetThisYear} label={[dur.start("yy")]} />
                  <BtnToggle selected={dur.start("yyyy-MM") === newDate(null, "yyyy-MM")} onChange={dur.handleSetThisMonth} label={[dur.start("MM")]} />
                  <BtnToggle selected={dur.start("yyyy-MM-dd") === newDate(null, "yyyy-MM-dd")} onChange={dur.handSetToday} label={[dur.start("dd")]} />
                </Stack>
              ) : undefined,
          },
          {
            field: "plate",
            width: 100,
            renderCell: (pa) =>
              pa.rowNode.type === "pinnedRow" ? (
                <>
                  {"to "}
                  <BtnPad label={dur.end("yy-MM-dd")}>
                    <Input type="date" value={dur.end()} onChange={(e) => dur.setDuration({ start: dur.start(), end: newDate(e.target.value) })} />
                  </BtnPad>
                </>
              ) : undefined,
          },
          {
            field: "model",
            width: 130,
            renderCell: (pa) =>
              pa.rowNode.type === "pinnedRow" ? (
                <>
                  {"<"}
                  <Btn label="Y" onClick={() => dur.moveYear(-1)} />
                  <Btn label="M" onClick={() => dur.moveMonth(-1)} />
                  <Btn label="D" onClick={() => dur.moveDay(-1)} />
                </>
              ) : undefined,
          },
          {
            field: "vip",
            width: 90,
            renderCell: (pa) =>
              pa.rowNode.type === "pinnedRow" ? (
                <>
                  <Btn label="Y" disabled={+dur.start("yyyy") >= +newDate(null, "yyyy")} onClick={() => dur.moveYear(1)} />
                  <Btn label="M" disabled={+dur.start("MM") >= +newDate(null, "MM")} onClick={() => dur.moveMonth(1)} />
                  <Btn label="D" disabled={+dur.start("dd") >= +newDate(null, "dd")} onClick={() => dur.moveDay(1)} />
                  {">"}
                </>
              ) : undefined,
          },
          {
            field: "total",
            type: "number",
            width: 105,
          },
          {
            field: "team",
            width: 80,
            renderCell: (pa) =>
              pa.rowNode.type === "pinnedRow" ? (
                <Stack direction="row" spacing={0.1}>
                  <BtnToggle selected={teamId === -1} onChange={() => setTeamId(-1)} label={["All"]} />
                  <BtnToggle selected={teamId === 0} onChange={() => setTeamId(0)} label={[teams_zh[0]]} />
                </Stack>
              ) : undefined,
          },
          {
            field: "more",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            renderCell: ({ row, rowNode }) =>
              rowNode.type !== "pinnedRow" ? (
                <DocsBtns
                  docsArr={row.docsArr}
                  clickUrl={navigate}
                  clickAppend={(type) =>
                    appendDoc({ type, sn: row.sn, date: newDate(), plate: row.plate, vip: row.vip, model: row.model }).then((res) =>
                      navigate(`/document/${res.slug}`)
                    )
                  }
                />
              ) : (
                <Stack direction="row" spacing={0.1}>
                  {teams.map((t, teamId) =>
                    !teamId ? null : <BtnToggle key={teamId} selected={team() === t || !team()} onChange={() => team(t)} label={[teams_zh[teamId]]} />
                  )}
                </Stack>
              ),
          },
        ]}
        rows={rows}
        // sx={{
        //   ".MuiDataGrid-overlayWrapper": { zIndex: 0 },
        //   "& .MuiDataGrid-columnHeaders": { backgroundColor: "#f8f8f8" },
        //   "& .MuiDataGrid-toolbarContainer": { padding: 0 },
        //   "& .MuiButton-root": { padding: "0px 4px" },
        //   "& .MuiBadge-badge": { top: "24px" },
        //   "& .MuiDataGrid-aggregationColumnHeaderLabel": { backgroundColor: "#fff", fontSize: 6.5, scale: "0.7" },
        //   "& .MuiDataGrid-row.Mui-selected": { fontWeight: "bold" },
        // }}
        initialState={{
          aggregation: {
            model: {
              sn: "size",
              total: "sum",
            },
          },
        }}
      />
    </Layout>

    //     renderSlug={({ id, value }) => <Btn onClick={() => navigate(`/project/${value}/${id}`)} children={value} />}
  );
};

export default PageProjects;
