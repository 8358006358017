import React from "react";
import { useImmer } from "use-immer";

export type Account = (typeof accounts)[number];
export const accounts = ["cash", "airtel", "mtn", "bank", "usd", "cny"] as const;

export const amounts = [0, 0, 0, 0, 0, 0];

type Entry = typeof entryInit;
const entryInit = { date: "2001-01-01", type: "", counterparty: "", description: "", amounts, note: "" };

type EntriesData = [string, string, string, string, number[], string][];
const entriesDataSample: EntriesData = [
  ["2023-07-07", "invoice", "wang_qiang", "BILL 230001", [1000, 0, 0, 0, 0, 0], "note..."],
  ["2023-07-07", "invoice", "qingdao", "BILL 230002", [4000, 0, 0, 0, 0, 0], ""],
  ["2023-07-07", "invoice", "zhang_yue", "BILL 230003", [3500, 0, 0, 0, 0, 0], ""],
];

const headers = ["date", "type", "counterparty", "description", ...accounts, "note"];

const openEntries = (dataArr: EntriesData): Entry[] => {
  return dataArr.map((data) => {
    const [date, type, counterparty, description, amounts, note] = data;
    return { date, type, counterparty, description, amounts, note };
  });
};

const useJournal = (date: string) => {
  const immEntries = useImmer(openEntries(entriesDataSample));
  const [entries, setEntries] = immEntries;
  //   const entries = ;
  let balance = 0;
  const rows = entries.map((entry) => {
    balance = entry.amounts.reduce((p, c) => p + c) + balance;
    return [entry.date, entry.type, entry.counterparty, entry.description, ...entry.amounts, entry.note, balance];
  });
  const summaries = amounts.map((_, i) => entries.length && entries.reduce((p, entry) => entry.amounts[i] + p, 0));
  return { immEntries, entries, setEntries, headers, rows, summaries, balance };
};

export default useJournal;
