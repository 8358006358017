import DocTable, { DocTableProps } from "./components/DocTable";

import Discount from "@mui/icons-material/Discount";
import Balance from "@mui/icons-material/Balance";

import { LocalAtm } from "@mui/icons-material";
import DocTd from "./components/DocTd";
import IconBtnFly from "./inputs/DocIconBtnFly";
import useDocContext from "./useDocContext";
import { jobsSummary } from "../utils/jobsSummary";
import { formatAmount, formatPercent } from "../utils/format";
import DocInput from "./inputs/DocInput";

const DocBill = () => {
  const { imm, disabled } = useDocContext();
  const [doc, imDoc] = imm;
  const sum = jobsSummary(doc.jobs);
  const total = (sum.subtotal + (doc.dis || 0)) * (1 + doc.tax);
  const MoneyIcon = { USD: "$", CNY: "￥", ZMK: "K" }[doc.currency || 0];

  // console.log("dis", doc.meta?.show?.dis, doc.dis);

  return (
    <DocTable
      heading={
        <div style={{ height: "26px" }}>
          Total Amount and Details
          <br />
          费用统计 ( {MoneyIcon} )
        </div>
      }
      style={{ height: "100%" }}
    >
      <tr>
        <DocTd right style={{ borderTop: 0, borderBottom: 0 }}>
          Labor Cost 人工费
        </DocTd>
        <DocTd width={80} right>
          {formatAmount(sum.job)}
        </DocTd>
      </tr>
      <tr>
        <DocTd right style={{ borderTop: 0, borderBottom: 0 }}>
          Parts and Materials 材料费
        </DocTd>
        <DocTd right>{formatAmount(sum.mat)}</DocTd>
      </tr>
      <tr>
        <DocTd right style={{ borderTop: 0, borderBottom: 0 }}>
          Sub-Total 小计 ( {MoneyIcon} )
        </DocTd>
        <DocTd right>{formatAmount(sum.subtotal)}</DocTd>
      </tr>

      {(doc.showDis || doc.dis) && (
        <tr>
          <DocTd right style={{ borderTop: 0, borderBottom: 0 }}>
            Discount ( {MoneyIcon} )
          </DocTd>
          <td>
            <DocInput // discount
              format={formatAmount}
              value={doc.dis}
              onInputDone={(v) =>
                imDoc((d) => {
                  d.dis = +v;
                })
              }
            />
          </td>
        </tr>
      )}
      <tr>
        <DocTd right style={{ borderTop: 0, borderBottom: 0 }}>
          Tax 税费 ( % )
          {disabled || (
            <IconBtnFly
              sx={{ right: -30 }}
              color={!doc.tax ? "warning" : "primary"}
              MuiIcon={Balance}
              onClick={() =>
                imDoc((d) => {
                  d.tax = doc.tax !== 0.16 ? 0.16 : 0;
                })
              }
            />
          )}
        </DocTd>
        <td>
          <DocInput // tax
            format={formatPercent}
            value={doc.tax}
            textAlign="right"
            onInputDone={(v) =>
              imDoc((d) => {
                d.tax = +v;
              })
            }
          />
        </td>
      </tr>
      <tr>
        <DocTd right style={{ borderTop: 0 }}>
          Total Amount 总额 ( {MoneyIcon} )
          {disabled || (
            <>
              <IconBtnFly
                sx={{ right: -30 }}
                color={!doc.showDis ? "warning" : "primary"}
                MuiIcon={Discount}
                onClick={() =>
                  imDoc((d) => {
                    d.showDis = !d.showDis;
                  })
                }
              />
              <IconBtnFly
                sx={{ right: -60 }}
                color={!doc.showTax ? "warning" : "primary"}
                MuiIcon={LocalAtm}
                onClick={() =>
                  imDoc((d) => {
                    d.showTax = !d.showTax;
                  })
                }
              />
            </>
          )}
        </DocTd>
        <DocTd right>
          <strong>{doc.showTax || doc.tax || doc.dis ? formatAmount(total) : "-"}</strong>
        </DocTd>
      </tr>
    </DocTable>
  );
};

export default DocBill;
