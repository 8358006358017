import { WatchQueryFetchPolicy, gql, useQuery } from "@apollo/client";
import { PostStatusEnum, RecsQuery, RecsQueryVariables, RootQueryToRecordConnectionWhereArgs } from "../types/graphql";
const queryRecs = gql`
  query Recs($where: RootQueryToRecordConnectionWhereArgs) {
    records(first: 100000, where: $where) {
      nodes {
        id
        slug
      }
    }
  }
`;
const useRecs = (where: RootQueryToRecordConnectionWhereArgs = {}, fetchPolicy: WatchQueryFetchPolicy = "network-only") => {
  const { data, refetch } = useQuery<RecsQuery, RecsQueryVariables>(queryRecs, {
    variables: { where: { stati: [PostStatusEnum.Draft, PostStatusEnum.Publish], ...where } },
    fetchPolicy,
  });
  const recs =
    data?.records?.nodes.map((n) => ({
      id: n.id,
      slug: n.slug || "",
    })) || [];
  return { recs, refetch };
};

export default useRecs;
