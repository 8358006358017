import useDocContext from "./useDocContext";
import { newDate } from "../utils/useDuration";

const DocFooter = ({ company, left, right }: { company?: boolean; left?: string; right?: string }) => {
  const { imm } = useDocContext();
  return (
    <div
      style={{
        fontSize: 11,
        display: "flex",
        justifyContent: "space-between",
        marginTop: 50,
      }}
    >
      {company ? (
        <>
          <div> </div>
          <div style={{ paddingRight: "20px" }}>
            <strong>Tulando Investments Limited</strong>
            <br />
            {newDate(imm[0].date)}
          </div>
        </>
      ) : (
        <>
          <div>{left}</div>
          <div style={{ marginRight: "150px" }}>{right}</div>
        </>
      )}
      {/* {type === "reception" && (
        <>
          <div>Receptionist 接待员:</div>
          <div style={{ marginRight: "150px" }}>Customer 客户:</div>
        </>
      )}
      {(type === "jobcard" || type === "checkcard") && (
        <>
          <div></div>
          <div style={{ marginRight: "150px" }}></div>
        </>
      )}
      {(type === "quotation" || type === "bill") &&
        (imm[0].showHeader ? (
          <>
            <div> </div>
            <div style={{ paddingRight: "20px" }}>
              <strong>Tulando Investments Limited</strong>
              <br />
              {newDate(imm[0].date)}
            </div>
          </>
        ) : (
          <>
            <div>Receptionist 接待员:</div>
            <div style={{ marginRight: "150px" }}>Customer 客户:</div>
          </>
        ))} */}
    </div>
  );
};

export default DocFooter;
