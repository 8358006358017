import { ThemeProvider as MuiProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import './app.css'
import { ThemeProviderProps } from "@emotion/react";

const ThemeProvider = ({ children, ...props }: Partial<ThemeProviderProps>) => {
  return (
    <MuiProvider theme={theme} {...props}>
      <CssBaseline />
      {children}
    </MuiProvider>
  );
};

export default ThemeProvider;
