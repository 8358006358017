import objectEntries from "./objectEntries";

const objectFill = <T extends Object>(init: T, fill: T) => {
  const res = { ...init };
  objectEntries(init).forEach(([k]) => {
    res[k] = fill[k];
  });
  return res;
};
export default objectFill;
