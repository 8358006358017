import { Navigate, useLocation } from "react-router-dom";
import useAuth from "./useAuth";

export const RouteAuth = ({ users, children }: { users?: string[]; children: JSX.Element }) => {
  const { user, pathLogin, errors } = useAuth();
  const location = useLocation();
  // console.log(user, users, errors);

  if (user && !errors && (!users || users.includes(user?.name))) {
    return children;
  }
  return <Navigate to={pathLogin} replace state={{ from: location }} />;
};
