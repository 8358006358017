import { Add, LibraryAdd } from "@mui/icons-material";
import DocTable from "./components/DocTable";
import DocTd from "./components/DocTd";
import DocIconBtn from "./inputs/DocIconBtn";
import DocAuto from "./inputs/DocAuto";
import RowMenu from "./inputs/RowMenu";
import DocTextBtn from "./inputs/DocTextBtn";
import React from "react";
import IconBtnFly from "./inputs/DocIconBtnFly";
import useDocContext from "./useDocContext";
import useTemplates from "../hooks/useTemplates";
// import useTemplates from "../hooks/useTemplates";
const pickItems = [
  "Ordinary Service 常规保养", //0
  "Engine Service 发动机检修", //1
  "Tire Service 轮胎", //2
  "Suspension Service 底盘检修", //3
  "Gearbox Service 变速箱检修", //4
  "Car Wash 洗车", //5
  "Air Conditioning Service 空调检修", //6
  "Panel Beating 钣金", //7
  "Power problem 动力受限", //8
  "Circuit Service 电路检修", //9
  "Spray Painting 喷漆", //10
  "Polishing/Waxing 抛光/打蜡", //11
];
const pickOrder = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [9, 10, 11],
];
const DocRequest = ({ pick, advice }: { pick?: boolean; advice?: boolean }) => {
  // const { work, insertNeed, app, imWork, deleteNeed } = useWork();
  const { imm, disabled, options } = useDocContext();
  const [doc, imDoc] = imm;
  const { templates } = useTemplates();
  // const handlePushTemplate = (request: string) => {
  //   const jobs = template(request, doc.model)?.jobs;
  //   if (jobs)
  //     imDoc((d) => {
  //       d.jobs.push(...jobs);
  //     });
  // };
  // const tdPick = (index: number) => (
  //   <React.Fragment key={index}>
  //     <DocTd width="25%" children={pickItems[index]} />
  //     <DocTd>
  //       <DocTextBtn
  //         children={doc.requests.includes(pickItems[index]) ? "✓" : "　"}
  //         onClick={() =>
  //           imm[1]((d) => {
  //             const i = doc.requests.indexOf(pickItems[index]);
  //             if (i === -1) d.requests.push(pickItems[index]);
  //             else d.requests.splice(i, 1);
  //           })
  //         }
  //       />
  //     </DocTd>
  //   </React.Fragment>
  // );
  // console.log("requests:", doc.requests);

  return (
    <div style={{ position: "relative" }}>
      {pick && (
        <DocTable noBorderTop heading="Service Items 送修项目">
          {pickOrder.map((r, rI) => (
            <tr key={rI}>
              {r.map((index) => (
                <React.Fragment key={index}>
                  <DocTd width="25%" children={pickItems[index]} />
                  <DocTd>
                    <DocTextBtn
                      children={doc.requests.includes(pickItems[index]) ? "✓" : "　"}
                      onClick={() =>
                        imm[1]((d) => {
                          const i = doc.requests.indexOf(pickItems[index]);
                          if (i === -1) d.requests.push(pickItems[index]);
                          else d.requests.splice(i, 1);
                        })
                      }
                    />
                  </DocTd>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </DocTable>
      )}
      <DocTable noBorderTop heading={advice ? "Advice 维修建议" : "Fault Phenomenon/Repair Requirements 故障现象/送修要求"}>
        {doc.requests.map((item, i) => {
          // console.log("item", item, "t", templates);

          const tempJobs = templates.find((t) => t.request === item && t.model === doc.model)?.jobs;

          return (
            <tr key={i}>
              <DocTd width="4%">
                {disabled || (
                  <>
                    <RowMenu
                      sx={{ left: -30 }}
                      clicked={(btn) =>
                        imDoc((d) => {
                          btn === "del" && d.requests.splice(i, 1);
                          btn === "mvUp" && d.requests.splice(i - 1, 0, d.requests.splice(i, 1)[0]);
                          btn === "mvDn" && d.requests.splice(i + 1, 0, d.requests.splice(i, 1)[0]);
                          btn === "inUp" && d.requests.splice(i, 0, "");
                          btn === "inDn" && d.requests.splice(i + 1, 0, "");
                        })
                      }
                      disable={{
                        mvUp: i <= 0,
                        mvDn: i >= doc.requests.length - 1,
                      }}
                    />
                    {/* {(doc.type === 3 || doc.type > 4) && ( */}
                    <IconBtnFly
                      MuiIcon={LibraryAdd}
                      sx={{ right: -30 }}
                      disabled={!tempJobs}
                      onClick={() => {
                        if (tempJobs)
                          imDoc((d) => {
                            d.jobs.push(...tempJobs);
                          });
                      }}
                    />
                    {/* )} */}
                  </>
                )}
                {i + 1}
              </DocTd>
              <DocTd>
                <DocAuto // order
                  multiline
                  textAlign="left"
                  options={options.requests}
                  value={doc.requests[i]}
                  onInputDone={(v) =>
                    imDoc((draft) => {
                      draft.requests[i] = v;
                    })
                  }
                />
              </DocTd>
            </tr>
          );
        })}
      </DocTable>
      {disabled || (
        <span style={{ position: "absolute", left: -30 }}>
          <DocIconBtn
            MuiIcon={Add}
            onClick={() =>
              imDoc((w) => {
                w.requests.push("");
              })
            }
          />
        </span>
      )}
    </div>
  );
};

export default DocRequest;
