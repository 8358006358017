import { VerticalAlignCenter, VerticalAlignTop } from "@mui/icons-material";

import { deduplicateVar } from "../utils/deduplicate";
import DocTd from "./components/DocTd";
import DocIconBtn from "./inputs/DocIconBtn";
import DocAuto from "./inputs/DocAuto";
import RowMenu from "./inputs/RowMenu";
import DocText from "./inputs/DocInput";
import { Box, FilledInput } from "@mui/material";
import React from "react";
import useDocContext from "./useDocContext";
import DocInput from "./inputs/DocInput";
import { formatAmount, serializeAmount } from "../utils/format";
import { Excerpt, excerptInit, jobInit } from "../v6/docs/constant-excerpt";

type P = { jobId: number; rowSpan: number; costRowSpan: number };
const DocItemsJob = ({ jobId, rowSpan, costRowSpan }: P) => {
  const { imm, options } = useDocContext();
  const [doc, imDoc] = imm;
  // const excerpt = doc.excerpt;
  const job = doc.jobs[jobId];
  // const merge = (job: excerpt["jobs"][number], preJob: Conte["job"]): Excerpts["document"]["jobs"][number] => ({
  //   ...job,
  //   code: preJob.code,
  //   label: preJob.label,
  //   cost: job.follow ? 0 : preJob.cost * excerpt.exchange[excerpt.currency],
  //   follow: job.follow || (preJob.cost === 0 && preJob.code === "0"),
  // });
  React.useEffect(() => {
    if (job.follow) {
      if (!jobId)
        imDoc((d) => {
          d.jobs[0].follow = false;
        });
      else if (job.cost)
        imDoc((d) => {
          d.jobs[jobId].cost = 0;
        });
    }
  }, [job.follow, job.cost]);
  return (
    <>
      <DocTd rowSpan={rowSpan}>
        <RowMenu
          sx={{ left: -30 }}
          clicked={(btn) => {
            imDoc((d) => {
              btn === "del" && d.jobs.splice(jobId, 1);
              btn === "mvUp" && d.jobs.splice(jobId - 1, 0, d.jobs.splice(jobId, 1)[0]);
              btn === "mvDn" && d.jobs.splice(jobId + 1, 0, d.jobs.splice(jobId, 1)[0]);
              btn === "inUp" && d.jobs.splice(jobId, 0, jobInit);
              btn === "inDn" && d.jobs.splice(jobId + 1, 0, jobInit);
            });
          }}
          disable={{
            mvUp: jobId === 0,
            mvDn: jobId === doc.jobs.length - 1,
          }}
        >
          <DocIconBtn
            color={"primary"}
            disabled={jobId === 0}
            MuiIcon={!job.follow ? VerticalAlignTop : VerticalAlignCenter}
            onClick={() =>
              imDoc((w) => {
                w.jobs[jobId].follow = !job.follow;
              })
            }
          />
        </RowMenu>
        {jobId + 1}
      </DocTd>
      <td rowSpan={rowSpan}>
        <DocAuto // job / code
          textAlign="center"
          value={job.code}
          format={(s) => s.toUpperCase()}
          options={deduplicateVar(options.jobs.map((j) => j.code).filter((f) => f))}
          onInputDone={(v) => {
            imDoc((d) => {
              d.jobs[jobId].code = v;
            });
          }}
          onPick={(v) => {
            const pre = options.jobs.find((f) => f.code === v);
            // find("job", value.toUpperCase(), "code");
            if (pre && v)
              imDoc((d) => {
                d.jobs[jobId] = pre;
              });
          }}
        />
      </td>
      <td rowSpan={rowSpan}>
        <DocAuto // job / label
          textAlign="left"
          multiline
          options={deduplicateVar(options.jobs.map((j) => j.label).filter((f) => f))}
          // values("job", "label", (p, c) => (p.cat_id || 0) - (c.cat_id || 0))}
          value={job.label}
          onInputDone={(v) =>
            imDoc((d) => {
              d.jobs[jobId].label = v;
            })
          }
          onPick={(v) => {
            const pre = options.jobs.find((f) => v === f.label); // find("job", v, "label");
            pre &&
              imDoc((d) => {
                d.jobs[jobId] = pre;
              });
            // ;
          }}
        />
      </td>
      {!!costRowSpan && (
        <DocTd rowSpan={costRowSpan}>
          <DocInput // job / cost
            // readOnly={disabled}
            format={formatAmount}
            // type="number"
            textAlign="right"
            value={job.cost}
            onInputDone={(v) =>
              imDoc((d) => {
                d.jobs[jobId].cost = serializeAmount(v);
              })
            }
          />
        </DocTd>
      )}
    </>
  );
};

export default DocItemsJob;
