import { FormControl, InputLabel, MenuItem, MenuItemProps, Select, SelectProps } from "@mui/material";
import React from "react";
import { ImmerHook } from "use-immer";

// export type PickProps = SelectProps & { imm?: ImmerHook<Object>; field?: string; width?: number | string; label?: string; itemProps: MenuItemProps[] };

type E = <O extends Object>(
  props: {
    imm?: ImmerHook<O>;
    width?: number | string;
    field?: keyof O;
    label?: string;
    itemProps: MenuItemProps[];
  } & SelectProps
) => JSX.Element;

const Pick: E = ({ imm, field, children, label, width, itemProps, value, onChange, ...ps }) => {
  return (
    <FormControl>
      <InputLabel children={label || (field as string)} shrink />
      <Select
        label={label || (field as string)}
        size="small"
        {...ps}
        // sx={{ width: width || "100%", ...ps.sx }}
        value={imm && field ? `${imm[0][field]}` : value}
        onChange={(e, c) =>
          imm && field
            ? imm[1]((dr) => {
                (dr as any)[field] = e.target.value;
              })
            : onChange && onChange(e, c)
        }
      >
        {itemProps.map((itemProp, i) => (
          <MenuItem key={i} {...itemProp} />
        ))}
      </Select>
    </FormControl>
  );
};

export default Pick;
