import React from "react";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import authContext, { GraphQLErrors, User } from "./authContext";
import { useMutAuth } from "./useMutAuth";
// import useAutoLock from "./useAutoLock";

let refreshIntervalId = 0;

let timerIntervalId = 0;

const REFRESH_INTERVAL = 299 * 1000;
const LOGOUT_TIME = 295 * 1000;
const TICK_TIME = 10 * 1000;
let timer = Date.now();
const resetTimer = () => (timer = Date.now());

const AuthProvider = ({ pathLogin = "/login", pathHome = "/home", children = null as React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = React.useState<User>(undefined);
  const [errors, setErrors] = React.useState<GraphQLErrors>(undefined);

  const { login, refresh } = useMutAuth();

  React.useEffect(() => {
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("pointermove", resetTimer);
    window.addEventListener("pointerdown", resetTimer);
    window.addEventListener("keydown", resetTimer);
    timerIntervalId = window.setInterval(() => Date.now() - timer > LOGOUT_TIME && onLogout(), TICK_TIME);

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("pointermove", resetTimer);
      window.removeEventListener("pointerdown", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.clearInterval(timerIntervalId);
    };
  }, []);

  const onLogin = async (username = "", password = "") => {
    await onLogout();

    const { data, errors } = await login({ variables: { username, password }, fetchPolicy: "network-only" });
    setErrors(errors);

    if (data?.login?.authToken && data.login.refreshToken && data.login.user) {
      //cookie
      localStorage.setItem("token", data.login.authToken);
      localStorage.setItem("refreshToken", data.login.refreshToken);

      //set app store user
      setUser({ id: data.login.user.id, name: data.login.user.name || "" });
      setErrors(undefined);

      // refresh token
      refreshIntervalId = window.setInterval(_refreshToken, REFRESH_INTERVAL);

      // navigate back
      navigate(location.state?.from?.pathname || pathHome);

      //log
    }
  };

  const _refreshToken = async () => {
    const jwtRefreshToken = localStorage.getItem("refreshToken");
    if (jwtRefreshToken) {
      const { data, errors } = await refresh({ variables: { jwtRefreshToken } });
      setErrors(errors);

      if (data?.refreshJwtAuthToken?.authToken) localStorage.setItem("token", data.refreshJwtAuthToken.authToken);
    }
  };

  const onLogout = () => {
    // cookie
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");

    // app user
    setUser(undefined);

    // refresh token
    window.clearInterval(refreshIntervalId);

    return new Promise((resolve) => resolve(null));
  };

  const value = { pathLogin, pathHome, user, errors, _refreshToken, onLogin, onLogout };

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

export default AuthProvider;
