import { ToggleButton, ToggleButtonProps } from "@mui/material";
import React from "react";
type P = { label: string[] } & Omit<ToggleButtonProps, "value">;
const BtnToggle = ({ label, ...ps }: P) => {
  return (
    <ToggleButton
      size="small"
      value=""
      // selected={app.pageDocsThisMonth}
      // onChange={() => setApp({ pageDocsThisMonth: !app.pageDocsThisMonth })}
      children={ps.selected ? label[0] : label[1] || label[0]}
      {...ps}
      sx={{ py: 0, px: 0.5, ...ps.sx }}
    />
  );
};

export default BtnToggle;
