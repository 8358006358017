/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { CropFree } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";

export type DocIconBtnProps = {
  MuiIcon?: (props: any) => JSX.Element | null;
} & IconButtonProps;

const DocIconBtn = ({ MuiIcon = CropFree as any, ...ps }: DocIconBtnProps) => (
  <IconButton className="no-print" size="small" color="warning" {...ps}>
    <MuiIcon fontSize="small" />
  </IconButton>
);
export default DocIconBtn;
