import { Box, Checkbox, Stack, styled } from "@mui/material";
import numeral from "numeral";
import React from "react";
import { formatAmount } from "../../utils/format";
import { monthLastDay, newDate } from "../../utils/useDuration";
import { Link, useParams } from "react-router-dom";
import useLabor from "../../hooks/useLabor2";
import TablePayroll from "./_components/TablePayroll";
import PureTable from "../../components/data-display/PureTable";
import objectEntries from "../../utils/objectEntries";
import PureInput from "../../components/inputs-pure/PureInput";
import Btn, { BtnPrint, BtnSave } from "../../components/inputs-pure/Btn";
import useEmployeeList, { departments, titles } from "../../hooks/useEmployeeList";
import usePayroll from "../../hooks/usePayroll";
import PurePick from "../../components/inputs-pure/PurePick";
import PayrollTable from "../../components/data-display/PayrollTable";
import Layout from "../../layout/Layout";
import Toolbar from "../../layout/Toolbar";
import ToolbarNavigate from "../../layout/ToolbarNavigate";
import RowMenu from "../../docPrint/inputs/RowMenu";

// const Portrait = styled("div")`
//   padding: 1rem;
//   @page {
//     // size: A4 portrait;
//   }
// `;

const Landscape = styled("div")`
  padding: 1rem;
  @page {
    size: A4 landscape;
  }
  table {
    border-collapse: collapse;
    border: 1px solid black;
  }
  td,
  th {
    border: 1px solid black;
  }
`;

const Table2 = styled("div")``;

const PageBreaker = styled("div")`
  @media print {
    page-break-after: always;
  }
  height: 2rem;
`;

const PagePayroll = () => {
  const { slug } = useParams();
  const { excerpt: employeeList } = useEmployeeList();
  const { excerpt, imExcerpt, payRollRows, excerptInit, update, edited, dataSheetRowInit, payRollSum } = usePayroll(slug || newDate(null, "yyyyMM"));
  console.log("payRollSum", payRollSum);

  // const labor = useLabor();
  if (!excerpt) return null;
  const data = excerpt.dataSheetRows;
  return (
    <Layout title={`Payroll ${slug}`}>
      <Landscape>
        <Toolbar>
          <ToolbarNavigate warning={edited} urlBack="/payrolls" />
          <Box flexGrow={1} />
          <BtnPrint onClick={window.print} />
          <BtnSave onClick={update} disabled={!edited} />
        </Toolbar>
        <div className="no-print">
          PagePayroll for {slug} , <Link to={`/payroll/${slug}/slips`}>slips</Link>
          <PureTable
            keys={[...excerpt.dataSheetKeys, "more"]}
            rows={excerpt.dataSheetRows.map((d, i) => ({ ...d, more: i }))}
            render={(r, k) => {
              if (k === "more")
                return (
                  <RowMenu
                    clicked={(key) => {
                      if (key === "del") imExcerpt((ex) => ex.dataSheetRows.splice(r, 1));
                      if (key === "inUp") imExcerpt((ex) => ex.dataSheetRows.splice(r, 0, dataSheetRowInit));
                      if (key === "inDn") imExcerpt((ex) => ex.dataSheetRows.splice(r + 1, 0, dataSheetRowInit));
                      if (key === "mvUp") imExcerpt((ex) => ex.dataSheetRows.splice(r, 0, dataSheetRowInit));
                    }}
                  />
                );
              if (k === "department")
                return <PurePick value={data[r][k]} values={departments} onPick={(v) => imExcerpt((ex) => (ex.dataSheetRows[r].department = v))} />;
              if (k === "title") return <PurePick value={data[r][k]} values={titles} onPick={(v) => imExcerpt((ex) => (ex.dataSheetRows[r].title = v))} />;
              if (k === "housing" || k === "transport" || k === "full")
                return <Checkbox checked={data[r][k]} onChange={(v) => imExcerpt((ex) => (ex.dataSheetRows[r][k] = !data[r][k]))} />;
              if (k === "name") return <PureInput value={data[r][k]} onInputDone={(v) => imExcerpt((ex) => (ex.dataSheetRows[r][k] = v as never))} />;
              return <PureInput type="number" value={data[r][k]} onInputDone={(v) => imExcerpt((ex) => (ex.dataSheetRows[r][k] = +v as never))} />;
            }}
          />
          <Btn onClick={() => imExcerpt((ex) => ex.dataSheetRows.push(excerptInit.dataSheetRows[0]))}>add a row</Btn>
        </div>
        <PayrollTable slug={slug || ""} keys={excerpt.payrollKeys} rows={[...payRollRows, payRollSum as any]} />
        <PageBreaker />
        <PayrollTable slug={slug || ""} keys={[...excerpt.payrollKeys.slice(0, 17), "Confirmed by Signature"]} rows={[...payRollRows, payRollSum as any]} />

        {/* <Portrait>
        {[...payroll.employeeTable, ...workshop.employeeTable].map((cells, i) => {
          const name = [...payroll.rowHeaders, ...workshop.rowHeaders][i];
          return (
            <div className="no-break-inside" style={{ borderBottom: "1px solid rgb(255, 144, 144)" }}>
              <h5 style={{ padding: "0 20px", margin: "0 10rem 0.5rem", textAlign: "center", backgroundColor: "#d8ebfb" }}>Pay Statement of Tulando</h5>
              <Stack direction="row" justifyContent="space-between">
                <small>Full Name: {name}</small>
                <small>
                  Period: {period.start} to {period.end}
                </small>
                <small>Payment Date: {newDate(null, "dd/MM/yyyy")}</small>
              </Stack>
              <Table>
                <thead>
                  <tr>
                    <th>Sn. </th>
                    <th>Full Name</th>
                    {payroll.colHeaders.map((h, i) => (
                      <th key={i} children={h} />
                    ))}
                    <th>Confirmed by Signature</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th />
                    <th>{name}</th>
                    {cells.map((cell, c) => (
                      <td key={c} children={formatAmount(cell)} />
                    ))}
                    <td />
                  </tr>
                </tbody>
              </Table>
              <Stack direction="row" justifyContent="space-between" pr={15} py={3}>
                <small>Received by:</small>
                <small>Date:</small>
              </Stack>
            </div>
          );
        })}
      </Portrait> */}
      </Landscape>
    </Layout>
  );
};

export default PagePayroll;
