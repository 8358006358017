import React from "react";
import useRecord from "../api/record/useRecord";
import useRecordMutation from "../api/record/useRecordMut";
import { makeVar, useReactiveVar } from "@apollo/client";
import papa from "papaparse";
import { produce } from "immer";
import useVar from "../utils/useVar";
import useSheet from "./useSheet";
import useExcerpt from "./useExcerpt";
import { employeeRows } from "./payroll/constant";
//    { dep: 0, name: "Nankonde Memory", lv: 2, year: 2, talk: 50, over: 26.5, work: 500, full: true, absent: 0, advance: 0, deduct: 0 },

type EmployeeKey = (typeof employeeKeys)[number];
const employeeKeys = ["name", "nid", "napasa", "fullname", "birth", "department", "title", "start", "housing", "transport"] as const;

export type Department = (typeof departments)[number];
export const departments = ["office", "workshop"] as const;

export type Title = (typeof titles)[number];
export const titles = ["volunteer", "apprentice", "technician", "senior", "manager"] as const;

export type Employee = typeof employeeInit;
export const employeeInit = {
  name: "",
  nid: "",
  napasa: "",
  fullname: "",
  birth: "",
  department: "office" as Department,
  title: "volunteer" as Title,
  start: "2023-01-01",
  end: null as null | string,
  housing: true,
  transport: true,
};
const varEmployeeList = makeVar([] as Employee[]);
const varEdited = makeVar(false);

const useEmployeeList = () => {
  const props = useExcerpt(varEmployeeList, varEdited, "employee-list");
  // console.log("e", employeeInit);

  return { employeeKeys, ...props };
};

export default useEmployeeList;
