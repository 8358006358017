import React from "react";
import useEmployeeList, { departments, titles } from "../../hooks/useEmployeeList";
import VarInput from "../../components/VarInput";
import ToolbarNavigate from "../../layout/ToolbarNavigate";
import Layout from "../../layout/Layout";
import Toolbar from "../../layout/Toolbar";
import { Box, Checkbox } from "@mui/material";
import { BtnDlgDelete, BtnDlgSubmit, BtnDlgTemplate } from "../../components/BtnDlg";
import { BtnPrint, BtnSave } from "../../components/inputs-pure/Btn";
import PureInput from "../../components/inputs-pure/PureInput";
import { Check, CheckBox } from "@mui/icons-material";
import PurePick from "../../components/inputs-pure/PurePick";

const PageEmployeeList = () => {
  const { excerpt, employeeKeys, imExcerpt, update, edited } = useEmployeeList();

  if (!excerpt) return null;
  return (
    <Layout title="Tulando Employee List">
      <Toolbar>
        <ToolbarNavigate warning={edited} urlBack="/index" />
        <Box flexGrow={1} />
        <BtnPrint onClick={window.print} />
        <BtnSave onClick={update} disabled={!edited} />
      </Toolbar>
      <table>
        <thead>
          <tr>
            {employeeKeys.map((k, i) => (
              <th key={i}>{k}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {excerpt.map((employee, i) => (
            <tr key={i}>
              {employeeKeys.map((k, j) => (
                <td key={j}>
                  {/* {employee[k]} */}
                  {k === "housing" || k === "transport" ? (
                    <Checkbox checked={employee[k]} onChange={() => imExcerpt((dr) => (dr[i][k] = !dr[i][k]))} />
                  ) : k === "title" ? (
                    <PurePick value={employee[k]} values={titles} onPick={(v) => imExcerpt((dr) => (dr[i].title = v))} />
                  ) : k === "start" ? (
                    <PureInput type="date" textAlign="center" value={employee[k]} onInputDone={(v) => imExcerpt((dr) => (dr[i][k] = v))} />
                  ) : k === "department" ? (
                    <PurePick value={employee[k]} values={departments} onPick={(v) => imExcerpt((dr) => (dr[i].department = v))} />
                  ) : (
                    <PureInput
                      textAlign={k === "birth" ? "center" : undefined}
                      value={employee[k]}
                      onInputDone={(v) => imExcerpt((dr) => (dr[i][k] = v as never))}
                    />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  );
};

export default PageEmployeeList;
