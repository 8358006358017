import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    // mode: "dark",
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          "& li": { fontSize: "11px", padding: "1px" },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#f4f4f4",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
          padding: "1px 1rem",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { "input[type=number]": { textAlign: "right" } },
        input: { font: "-webkit-control", padding: 0, minWidth: "40px" },
      },
    },
  },
});
export default theme;
