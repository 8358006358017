import React from "react";

// import { Doc, docCreateInput, docToUpdateInput } from "../docs/constant-doc";
import { gql, useMutation } from "@apollo/client";

import { Record, recordInit } from "./constant";
import {
  CreateRecordMutation,
  CreateRecordMutationVariables,
  DeleteRecordMutation,
  DeleteRecordMutationVariables,
  UpdateRecordInput,
  UpdateRecordMutation,
  UpdateRecordMutationVariables,
} from "../types/graphql";

const mutationCreateRecord = gql`
  mutation CreateRecord($input: CreateRecordInput = {}) {
    createRecord(input: $input) {
      record {
        id
        slug
        excerpt(format: RAW)
      }
    }
  }
`;

const mutationDeleteRecord = gql`
  mutation DeleteRecord($input: DeleteRecordInput = { id: "" }) {
    deleteRecord(input: $input) {
      deletedId
      record {
        id
        slug
        excerpt(format: RAW)
      }
    }
  }
`;

const mutationUpdateRecord = gql`
  mutation UpdateRecord($input: UpdateRecordInput = { id: "" }) {
    updateRecord(input: $input) {
      record {
        id
        slug
        excerpt(format: RAW)
      }
    }
  }
`;

const useRecordMutation = (refetch: () => Promise<any>) => {
  const [_append] = useMutation<CreateRecordMutation, CreateRecordMutationVariables>(mutationCreateRecord);
  const [_remove] = useMutation<DeleteRecordMutation, DeleteRecordMutationVariables>(mutationDeleteRecord);
  const [_update] = useMutation<UpdateRecordMutation, UpdateRecordMutationVariables>(mutationUpdateRecord);

  const append = async (record: Partial<Record>) => {
    const { id, ...input } = { ...recordInit, title: record.slug, ...record };
    const { data } = await _append({ variables: { input } });
    await refetch();
    return data?.createRecord?.record;
  };

  const remove = async ({ id }: { id: string }) => {
    if (!id) return;
    const { data } = await _remove({ variables: { input: { id } } });
    await refetch();
    return data?.deleteRecord?.record;
  };

  const update = async (record: Partial<Record> & { id: string }) => {
    const input = { ...record, title: record.slug };
    const { data } = await _update({ variables: { input } });
    await refetch();
    return data?.updateRecord?.record;
  };

  return { append, remove, update };
};

export default useRecordMutation;
