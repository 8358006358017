import { Box, FormControl, FormLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import React from "react";
import useDocContext from "./useDocContext";
import Pick from "../components/Pick";
import { CURRENCIES, Currency } from "../v6/docs/constant-excerpt";
import { newDate } from "../utils/useDuration";
import DocInput from "./inputs/DocInput";
import Pop from "./inputs/Pop";
import { Team, teams } from "../v6/docs/constant-title";
import Input from "../components/Input";
const DocSn = () => {
  const { imm, disabled } = useDocContext();
  const [doc, imDoc] = imm;
  const exchanges = { ...doc.exchange, ZMK: 1 };
  // const [y, m, d] = date.split("-");
  // console.log("snDate date", date);

  return (
    <div style={{ position: "relative" }}>
      {disabled || (
        <Stack component="span" className="no-print" direction="row" sx={{ position: "absolute", left: 0 }} spacing={1}>
          <Pick imm={imm} field="teamId" value={teams[doc.teamId]} itemProps={teams.map((t, i) => ({ children: t, value: i }))} />
          <Pick
            label="Currency"
            value={doc.currency}
            onChange={(e) =>
              imDoc((d) => {
                const v = e.target.value as Currency;
                const oldExchange = exchanges[doc.currency];
                const newExchange = exchanges[v];
                const scale = oldExchange / newExchange;
                d.currency = v;
                d.jobs.map((job) => {
                  job.cost = job.cost * scale;
                  job.mats.map((mat) => {
                    mat.cost = mat.cost * scale;
                    mat.rate = mat.rate * scale;
                  });
                });
              })
            }
            itemProps={CURRENCIES.map((c) => ({ children: c, value: c }))}
          />

          <Input
            label="Exchange"
            disabled={doc.currency === "ZMK"}
            inputProps={{ sx: { width: 70 } }}
            type="number"
            value={exchanges[doc.currency]}
            onChange={(e) =>
              imDoc((d) => {
                d.exchange[doc.currency] = +e.target.value;
              })
            }
          />
        </Stack>
      )}
      <h6
        style={{
          textAlign: "right",
          margin: 2,
          WebkitTextFillColor: "#0070c0",
        }}
      >
        No. 编号：{doc.sn}
      </h6>
      <h6 style={{ textAlign: "right", margin: 2 }}>
        {/* Date. 日期 {d} D日/ {m} M月/ {y} Y年 */}
        Date. 日期 {newDate(doc.date, "dd")} D日/ {newDate(doc.date, "MM")} M月/ {newDate(doc.date, "yyyy")} Y年
      </h6>
      {/* <PadTextImm disabled={disabled} imm={imm} field="slug" type="number" sx={{ right: -30, top: -10 }} /> */}
      <Pop sx={{ right: -30, top: -10 }}>
        <DocInput field="sn" type="number" autoFocus />
      </Pop>
      <Pop sx={{ right: -30, top: 10 }}>
        <DocInput field="date" type="date" autoFocus />
      </Pop>
      {/* <PadTextImm disabled={disabled} imm={imm} field="date" type="date"  /> */}
    </div>
  );
};

export default DocSn;
