import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { ImmerHook } from "use-immer";

type E = <O extends Object>(
  props: {
    imm?: ImmerHook<O>;
    width?: number | string;
    field?: keyof O;
  } & TextFieldProps
) => JSX.Element;

export const Input: E = ({ value, width, imm, onChange, field, ...props }) => (
  <TextField
    size="small"
    label={field as string}
    InputLabelProps={{ shrink: true }}
    value={imm && field ? `${(imm[0] as any)[field]}` : value}
    onChange={(e) =>
      imm && field
        ? imm[1]((dr) => {
            (dr as any)[field] = props.type === "number" ? +e.target.value : e.target.value;
          })
        : onChange && onChange(e)
    }
    {...props}
    inputProps={{ sx: { width }, ...props.inputProps }}
  />
);
export default Input;
