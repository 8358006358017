import { GridRow, DataGridPremium, DataGridPremiumProps } from "@mui/x-data-grid-premium";
import React from "react";
import XDataToolbar from "../../layout/XDataToolbar";

const DataGrid = (props: DataGridPremiumProps) => {
  const MemoizedRow = React.memo(GridRow);

  React.useEffect(() => {
    document.getElementsByClassName("MuiDataGrid-main")[0]?.childNodes?.forEach((ch) => ch.textContent === "MUI X Missing license key" && ch.remove());
  });
  return (
    <DataGridPremium
      hideFooter
      hideFooterSelectedRowCount
      defaultGroupingExpansionDepth={-1}
      rowHeight={32}
      columnHeaderHeight={40}
      // experimentalFeatures={{ newEditingApi: true }}
      // onRowSelectionModelChange={(ids) => setSelectedIds(ids)}
      {...props}
      slots={{
        toolbar: () => <XDataToolbar />,
        row: MemoizedRow,
        ...props.slots,
      }}
      sx={{
        height: "100vh",
        ".MuiDataGrid-overlayWrapper": { zIndex: 0 },
        "& .MuiDataGrid-columnHeaders": { backgroundColor: "#f8f8f8" },
        "& .MuiDataGrid-toolbarContainer": { padding: 0 },
        "& .MuiButton-root": { padding: "0px 4px" },
        "& .MuiBadge-badge": { top: "24px" },
        "& .MuiDataGrid-aggregationColumnHeaderLabel": { backgroundColor: "#fff", fontSize: 6.5, scale: "0.7" },
        "& .MuiDataGrid-row.Mui-selected": { fontWeight: "bold" },
        ...props.sx,
      }}
    />
  );
};

export default DataGrid;
