type AmoJobs = { cost: number; mats: { qty: number; cost: number; rate: number }[] }[];

export const jobsSummary = (jobs: AmoJobs) => {
  // console.log("jobs", jobs);

  const job = jobs.length && jobs.reduce((p, c) => p + c.cost, 0);
  const mats = jobs.map((j) => j.mats).flat();
  const mat = mats ? mats.reduce((p, c) => p + c.rate * c.qty, 0) : 0;
  const mat_cost = mats ? mats.reduce((p, c) => p + c.cost * c.qty, 0) : 0;
  const subtotal = job + mat;
  return { job, mat, mat_cost, subtotal };
};

// export const getDocAmount = ({doc}) => {
//   const { dis, tax } = doc;
//   const { job, mat, subtotal } = getJobMatTotal(doc.jobs);
//   const total = (subtotal + dis) * (1 + tax);
//   return { job, mat, subtotal, total };
// };

// export const getProAmount = (pro: DATA["pro"]): ProAmo => {
//   const { tax, bill, paid } = pro;
//   const { job, mat, subtotal, mat_cost } = getJobMatTotal(pro.jobs);
//   // const dis = total - paid;
//   const paidBefTax = paid - tax;
//   const percent = !subtotal ? 0 : paidBefTax / subtotal;
//   const pay_job = Math.round(job * percent);
//   const pay_mat = Math.round(mat * percent);
//   const gross = paid - job - mat_cost - tax;
//   return { job, mat, pay_job, pay_mat, mat_cost, bill, gross };
// };
