import { GraphQLError } from "graphql";
import React from "react";

export type User = { id: string; name: string } | undefined;

export type GraphQLErrors = readonly GraphQLError[] | undefined;

const authContext = React.createContext({
  pathLogin: "/login",
  pathHome: "/home",
  user: undefined as User,
  errors: undefined as GraphQLErrors,
  onLogin: (username: string, password: string) => {},
  onLogout: () => {},
  _refreshToken: () => {},
});

export default authContext;
