import { ImmerHook } from "use-immer";

export default function (imm: ImmerHook<any>, v?: any, ...ks: (string | number)[]) {
  if (ks) {
    const [k1, k2, k3, k4] = ks;
    if (k1 !== undefined) {
      if (k2 !== undefined) {
        if (k3 !== undefined) {
          if (k4 !== undefined) {
            if (v !== undefined)
              return imm[1]((dr: any) => {
                dr[k1][k2][k3][k4] = v;
              });
            return imm[0][k1][k2][k3][k4];
          }
          if (v !== undefined)
            return imm[1]((dr: any) => {
              dr[k1][k2][k3] = v;
            });
          return imm[0][k1][k2][k3];
        }
        if (v !== undefined)
          return imm[1]((dr: any) => {
            dr[k1][k2] = v;
          });
        return imm[0][k1][k2];
      }
      if (v !== undefined)
        return imm[1]((dr: any) => {
          dr[k1] = v;
        });
      return imm[0][k1];
    }
    if (v !== undefined) return imm[1](v);
    return imm[0];
  }
}
