import { Box, Stack } from "@mui/material";
import React from "react";

const Toolbar = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      className="no-print"
      direction="row"
      // justifyContent="end"
      alignItems="center"
      width="100%"
      spacing={1}
    >
      {children}
    </Stack>
  );
};

export default Toolbar;
