import { InputBase, InputBaseProps, TextField, TextFieldProps } from "@mui/material";
import { CSSProperties } from "react";
import useDocContext from "../useDocContext";
import React from "react";
import { Doc } from "../../v6/docs/constant-doc";

const focusSelect = ["-", "null", " ", "0", "/", "0.0"];

export type DocInputProps = InputBaseProps & {
  textAlign?: CSSProperties["textAlign"];
  field?: keyof Doc;
  format?: (s: any) => string;
  onInputDone?: (v: string) => void;
  serialize?: (str: string) => string | number;
};
const DocInput = ({ textAlign, value, style, field, format, serialize, onFocus, onBlur, onInputDone, ...props }: DocInputProps) => {
  const { imm, disabled } = useDocContext();
  //值
  const theValue = value !== undefined ? `${value}` : field ? `${imm[0][field]}` : "";

  //显示的字符
  const [str, setStr] = React.useState(`${theValue}`);
  // console.log("str", str, "value", value);

  //值转显示的字符
  React.useEffect(() => {
    setStr(format ? format(theValue) : `${theValue}`);
  }, [theValue]);
  // 焦点事件：自动全选
  const handleFocus: InputBaseProps["onFocus"] = (e) => {
    if (disabled) return;
    if (focusSelect.includes(e.target.value) || !theValue) e.target.select();
    if (onFocus) onFocus(e);
  };
  //输入事件：显示字符
  const handleChange: InputBaseProps["onChange"] = (e) => {
    setStr(e.target.value);
  };
  //模糊事件：赋值
  const handleBlur: InputBaseProps["onBlur"] = (e) => {
    if (disabled) return;
    const v = (props.type === "number" ? +e.target.value : e.target.value) as never;
    if (onInputDone && e.target.value != theValue) return onInputDone(v);
    if (!imm && onBlur) return onBlur(e);
    if (field)
      return imm[1]((d) => {
        const newValue = (serialize ? serialize(v) : v) as never;
        if (newValue !== d[field]) d[field] = newValue;
      });
  };
  return (
    <InputBase
      {...props} //
      inputProps={{ style: { ...style, textAlign } }}
      value={str}
      onFocus={handleFocus}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
export default DocInput;
