import { serializeSn } from "../../utils/format";
import { Type } from "./constant-title";

export type Slug = typeof slugInit;
export const slugInit = { sn: 0, type: "r" as Type };

export const slugParse = (str: string) => {
  const sn = +str.slice(0, -2);
  const type = str.slice(-2, -1) as Type;
  return { sn, type };
};

export const slugSerialize = (slug: Slug) => {
  return `${serializeSn(slug.sn)}${slug.type}`;
};
