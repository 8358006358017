import React from "react";
import { ImmerHook, useImmer } from "use-immer";
import { optionsArrInit } from "../hooks/useTemplates";
import { Doc, docInit } from "../v6/docs/constant-doc";

type Value = typeof valueInit;
const valueInit = {
  imm: [{ ...docInit.f }, () => {}] as ImmerHook<Doc>,
  disabled: false,
  options: optionsArrInit,
};
const docContext = React.createContext(valueInit);

export const DocContextProvider = ({ children, ...value }: Value & { children: React.ReactNode }) => {
  return <docContext.Provider value={value}>{children}</docContext.Provider>;
};

const useDocContext = () => React.useContext(docContext);
export default useDocContext;
