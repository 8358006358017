import { ArrowLeft, Refresh } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid-premium";
import React from "react";
import { useNavigate } from "react-router-dom";

const XDataToolbar = () =>
  //   {
  //   ids,
  //   rows,
  // }: {
  //   ids: (number | string)[];
  //   rows: { id: number | string; total?: number; labor?: number; spare?: number }[];
  // }
  {
    const navigate = useNavigate();
    // const sum = {
    //   count: ids.length,
    //   labor: rows?.length && rows.reduce((p, c) => (ids.includes(c.id) ? p + (c?.labor || 0) : p), 0),
    //   spare: rows?.length && rows.reduce((p, c) => (ids.includes(c.id) ? p + (c?.spare || 0) : p), 0),
    //   total: rows?.length && rows.reduce((p, c) => (ids.includes(c.id) ? p + (c?.total || 0) : p), 0),
    // };

    return (
      <Stack direction="row" justifyContent="space-between" sx={{ backgroundColor: "#f8f8f8" }}>

        <Button startIcon={<ArrowLeft />} size="small" onClick={() => navigate("/index")}>
          Home
        </Button>

        <Stack direction="row" pl={0.5} spacing={1}>
          <Button startIcon={<Refresh />} size="small" onClick={() => navigate("/refresh")}>
            Refresh
          </Button>
          <GridToolbar />
        </Stack>
        {/* <span>
        {(["count", "labor", "spare", "total"] as const).map(
          (k) =>
            !!sum[k] && (
              <React.Fragment key={k}>
                {k === "count" ? "selected :" : k}
                <strong style={{ padding: "0 1rem" }}>{numeralAmount(sum[k])}</strong>
              </React.Fragment>
            )
        )}
      </span> */}
      </Stack>
    );
  };

export default XDataToolbar;
