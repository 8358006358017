import { Autocomplete, AutocompleteProps, InputBase, InputBaseProps } from "@mui/material";
import { CSSProperties } from "react";
import useDocContext from "../useDocContext";
import React from "react";
import { Doc } from "../../v6/docs/constant-doc";

const focusSelect = ["-", "null", " ", "0", "/", "0.0"];

export type DocAutoProps = {
  textAlign?: CSSProperties["textAlign"];
  multiline?: boolean;
  field?: keyof Doc;
  onPick?: (text: string) => void;
  onInputDone?: (str: string) => void;
  format?: (str: string) => string;
  serialize?: (str: string) => string;
} & Omit<AutocompleteProps<string, false, true, true>, "renderInput">; // React.ComponentProps<"td"> &

const DocAuto = ({ textAlign, style, multiline, field, format, onPick, serialize, value, onInputDone, ...rest }: DocAutoProps) => {
  const { imm, disabled } = useDocContext();
  //值
  const theValue = value !== undefined ? `${value}` : field ? `${imm[0][field]}` : "";
  //显示的字符
  const [text, setText] = React.useState(`${theValue}`);
  //值转显示的字符
  React.useEffect(() => {
    setText(format ? format(theValue) : theValue);
  }, [theValue]);
  // 焦点事件：自动全选
  const handleFocus: InputBaseProps["onFocus"] = (e) => {
    if (disabled) return;
    if (focusSelect.includes(e.target.value)) e.target.select();
    // setStr(theValue);
  };
  //模糊事件：赋值
  const handelBlur = (v: string) => {
    if (disabled) return;
    // console.log("change", v, imm);
    if (field) {
      return imm[1]((d) => {
        const newValue = (serialize ? serialize(v) : v) as never;
        if (newValue !== d[field]) d[field] = newValue;
      });
    }
  };
  return (
    <Autocomplete
      disableClearable
      freeSolo
      value={text}
      onChange={(e, v) => {
        handelBlur(v);
        onPick && onPick(v);
      }}
      {...rest}
      onInputChange={(e, v) => setText(v)}
      onBlur={() => onInputDone && onInputDone(text)}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, ...rest2 } = params;
        return (
          <InputBase
            {...params.InputProps}
            multiline={multiline}
            onFocus={handleFocus}
            onBlur={(e) => {
              handelBlur(e.target.value);
              if (params.inputProps.onBlur) params.inputProps.onBlur(e as any);
            }}
            {...rest2}
            inputProps={{ style: { ...style, textAlign }, ...rest2.inputProps }}
          />
        );
      }}
    />
  );
};

export default DocAuto;
