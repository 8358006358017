import React from "react";
import BtnDlg from "../components/BtnDlg";
import { ArrowLeft, Refresh } from "@mui/icons-material";
import Btn from "../components/inputs-pure/Btn";
import { useNavigate } from "react-router-dom";

const ToolbarNavigate = ({ warning = false, urlBack = "/index", urlReload = "/refresh" }) => {
  const navigate = useNavigate();
  const back = () => navigate(urlBack);
  const reload = () => navigate(urlReload);
  return warning ? (
    <>
      <BtnDlg
        color="error"
        label="Back"
        startIcon={<ArrowLeft />}
        title="Warning!"
        btnProps={[{ color: "error", children: "i'm Sure", onClick: back }]}
        children="Go Back Without Save?"
      />
      <BtnDlg
        color="error"
        label="Reload"
        startIcon={<Refresh />}
        title="Warning!"
        btnProps={[{ color: "error", children: "i'm Sure", onClick: reload }]}
        children="Reload Without Save?"
      />
    </>
  ) : (
    <>
      <Btn color="primary" startIcon={<ArrowLeft />} label="Back" onClick={back} />
      <Btn color="primary" startIcon={<Refresh />} label="Reload" onClick={reload} />
    </>
  );
};

export default ToolbarNavigate;
