/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";
import Btn, { BtnProps } from "./inputs-pure/Btn";
import { Stack } from "@mui/material";

export type DlgProps = DialogProps & {
  title?: string;
  btnProps: BtnProps[];
};
export default function Dlg({ title, btnProps, children, ...rest }: DlgProps) {
  return (
    <Dialog {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack sx={{ pt: 1 }} spacing={1}>
          {children}
        </Stack>
      </DialogContent>
      <DialogActions>
        {btnProps.map((ps, i) => (
          <Btn key={i} {...ps} />
        ))}
      </DialogActions>
    </Dialog>
  );
}
