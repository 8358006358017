import { TextField, TextFieldProps } from "@mui/material";
import Pad from "./Pop";

type P = TextFieldProps;

const PadText = ({value, sx, ...props }: P) => {
  // const [val, set] = state;
  return (
    <Pad sx={sx as any}>
      <TextField
        variant="standard"
        autoFocus
        sx={{ margin: 0.5 }}
        {...props}
        value={value}
        // onChange={(e) => set(e.target.value)}
      />
    </Pad>
  );
};
export default PadText;
