import DocTable from "./components/DocTable";
import DocTd from "./components/DocTd";
import DocTdLabel from "./components/DocTdLabel";

import DocAuto from "./inputs/DocAuto";
import DocInput from "./inputs/DocInput";
import useDocContext from "./useDocContext";
import numeral from "numeral";
import { deduplicateVar } from "../utils/deduplicate";
import Input from "../components/Input";
import { formatMileage, formatModel, formatPlate, formatTel, serializeMilage, serializeTitleNode } from "../utils/format";
import objectFill from "../utils/objectFill";
import { DRIVES, FUELS, ownerInit } from "../v6/docs/constant-excerpt";

const DocVehicle = ({ detail = false, owner = false }) => {
  const { imm, disabled, options } = useDocContext();
  const [doc, imDoc] = imm;

  // const text = (value: DocInputProps["value"], onChange: (v: string) => void) => (
  //   <td>
  //     <DocInput textAlign="center" value={value || ""} onChange={(e) => onChange(e.target.value)} />
  //   </td>
  // );
  const TrBasic = (
    <tr>
      <DocTdLabel width="15%" en="Number Plate" br zh="车牌号码" />
      <DocTd>
        <DocAuto
          options={deduplicateVar(options.vehicles.map((v) => v.plate)).filter((f) => f)}
          field="plate"
          format={formatPlate}
          serialize={(str) => str.replaceAll(" ", "")}
          onPick={(v) => {
            const veh = options.vehicles.find((ve) => ve.plate === v);
            if (veh) imDoc({ ...doc, ...veh });
          }}
        />
      </DocTd>

      <DocTdLabel width="15%" en="Vehicle Model" br zh="车辆型号" />
      <DocTd width="17%">
        <DocAuto multiline options={deduplicateVar(options.vehicles.map((v) => v.model))} field="model" format={formatModel} />
      </DocTd>

      <DocTdLabel width="15%" en="The Mileage" br zh="行驶里程" />
      <DocTd width="17%">
        <DocInput format={formatMileage} serialize={serializeMilage} field="mileage" />
      </DocTd>
    </tr>
  );

  const TrDetail = (
    <>
      <tr>
        <DocTdLabel en="Engine Number" br zh="引擎号" />
        <DocTd>
          <DocInput field="engine" />
        </DocTd>

        <DocTdLabel en="Engine Capacity" br zh="引擎排量" />
        <DocTd>
          <DocInput value={doc.capacity} field="capacity" />
        </DocTd>

        <DocTdLabel en="Year of Make" br zh="出产年份" />
        <DocTd>
          <DocInput field="made" />
        </DocTd>
      </tr>
      <tr>
        <DocTdLabel en="Chassis Number" br zh="车架号" />
        <DocTd>
          <DocInput field="chassis" />
        </DocTd>

        <DocTdLabel en="Fuel Type" br zh="燃油类型" />
        <DocTd>
          <DocAuto options={FUELS} field="fuel" />
        </DocTd>

        <DocTdLabel en="Drive Model" br zh="驱动模式" />
        <DocTd>
          <DocAuto options={DRIVES} field="drive" />
        </DocTd>
      </tr>
      <tr>
        <DocTdLabel en="Tire Specification" br zh="轮胎规格" />
        <DocTd>
          <DocInput field="tire" />
        </DocTd>
        <td colSpan={4} />
      </tr>
    </>
  );
  const TrOwner = (
    <tr>
      <DocTdLabel width="15%" en="Owner's Name" br zh="车主姓名" />
      <DocTd>
        <DocAuto
          options={deduplicateVar(options.owners.map((c) => c.owner).filter((f) => f))}
          field="owner"
          onPick={(v) => {
            const con = options.owners.find((w) => w.owner === v);
            if (con) {
              imDoc((d) => {
                d.tel = con.tel;
                d.vip = con.vip;
              });
            }
          }}
        />
      </DocTd>

      <DocTdLabel width="15%" en="Telephone No." br zh="联系电话" />
      <DocTd>
        <DocAuto
          options={deduplicateVar(options.owners.map((c) => c.tel).filter((f) => f))}
          field="tel"
          onPick={(v) => {
            const con = options.owners.find((w) => w.tel === v);
            if (con) {
              imDoc((d) => {
                d.owner = con.owner;
                d.vip = con.vip;
              });
            }
          }}
          format={formatTel}
        />
      </DocTd>

      <DocTdLabel width="15%" en="Number of VIP" br zh="会员卡号" />
      <DocTd>
        <DocAuto
          options={deduplicateVar(options.owners.map((c) => c.vip)).filter((f) => f)}
          field="vip"
          onPick={(v) => {
            const con = options.owners.find((w) => w.vip === v);
            if (con) imDoc({ ...doc, ...objectFill(ownerInit, con) });
          }}
        />
      </DocTd>
    </tr>
  );
  // if (!doc) return null;
  if (detail)
    return (
      <>
        <DocTable heading="Vehicle information 车辆信息">
          {TrBasic}
          {TrDetail}
        </DocTable>
        <DocTable noBorderTop heading="owner's information 车主信息">
          {TrOwner}
        </DocTable>
      </>
    );
  if (owner)
    return (
      <DocTable heading="Vehicle and owner's information 车辆及车主信息">
        {TrBasic}
        {TrOwner}
      </DocTable>
    );
  return <DocTable heading="Vehicle information 车辆信息">{TrBasic}</DocTable>;
};

export default DocVehicle;
