/* eslint-disable react/jsx-props-no-spreading */
import { Stack, styled } from "@mui/material";

import { ComponentProps } from "react";
import DocTable from "./components/DocTable";
import DocInput from "./inputs/DocInput";
import useDocContext from "./useDocContext";
// import InvoiceTable, { TxtIn } from '../InvoiceTable';

const Ol = styled("ol")({
  fontSize: 11,
  fontWeight: 600,
  WebkitTextFillColor: "#0070c0",
  paddingInlineStart: 20,
  marginTop: 5,
  marginBottom: 0,
  "& li": {
    textAlign: "left",
    width: "100%",
  },
});

const DocNotice = () => {
  const { imm } = useDocContext();
  // const { work, imWork } = useWork();
  return (
    <DocTable style={{ height: "100%" }}>
      <tr>
        <td>
          <Stack>
            <Ol>
              <span> NB备注:</span>
              <li>
                Please note this quotation is based on visual inspection and is bound to fluctuate with extra work being found on certain parts. If any
                additional work be deemed necessary, we shall communicate to you for approval.
                <br />
                此报价仅包含以上所列维修项目，若在维修过程中发现其他问题，本店将及时与您沟通，以商讨维修方案和确定所需配件及费用。
              </li>
              <li>
                The materials cost in the above quotation is the estimated price, which is affected by the supplier and the procurement time, allowing about 20%
                floating.
                <br />
                以上报价中的材料费为预估价，受供应商及采购时间影响，允许20%左右浮动。
              </li>
              <li>
                If we issue a quotation or test result after our time-consuming testing or inquiring with lots of shops, the customer chooses to give up
                repairing, we will charge for the testing and labor based on the actual time spent or other shop charges.
                <br />
                经我司耗时检测、并向多家配件店询价后，出具了检测结果或书面报价，而客户选择放弃维修的，本店将根据实际所耗工时或外店收费向客户收取检测费及工时费。
              </li>
              <li>The validity of this quotation is 7 days. 本次报价有效期为7天。</li>
            </Ol>
            <ul
              style={{
                width: "100%",
                fontSize: 11,
                paddingInlineStart: 0,
                margin: 0,
                listStyle: "none",
              }}
            >
              <li>
                <DocInput textAlign="left" multiline sx={{ color: "#bb0000" }} field="notice" />
              </li>
            </ul>
          </Stack>
        </td>
      </tr>
    </DocTable>
  );
};

export default DocNotice;
