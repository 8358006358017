import { Photo } from "@mui/icons-material";
import IconBtnFly from "./inputs/DocIconBtnFly";
import PadText from "./inputs/PadText";
import useDocContext from "./useDocContext";

const DocHeader = ({ heading = "" }) => {
  const { imm, disabled } = useDocContext();
  const [doc, imDoc] = imm;
  return (
    <>
      {doc.showHeader && <img src="/head1.png" alt="tulando title" />}
      {disabled || (
        <IconBtnFly
          sx={{ right: 10 }}
          color={!doc.showHeader ? "warning" : "primary"}
          MuiIcon={Photo}
          onClick={() =>
            imDoc((d) => {
              d.showHeader = !doc.showHeader;
            })
          }
        />
      )}

      {disabled || (
        <PadText
          sx={{ right: 10, mt: 2.5 }}
          value={doc.heading}
          onChange={(e) =>
            imDoc((d) => {
              d.heading = e.target.value;
            })
          }
        />
      )}

      <h1 dangerouslySetInnerHTML={{ __html: doc.heading || heading }} />
    </>
  );
};

export default DocHeader;
