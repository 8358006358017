import { Slider, Stack, TextField } from "@mui/material";
import { produce } from "immer";
import jsonParse from "../utils/jsonParse";
import DocTable from "./components/DocTable";
import DocTd from "./components/DocTd";
import DocTdLabel from "./components/DocTdLabel";
import DocInput from "./inputs/DocInput";
import DocTextBtn from "./inputs/DocTextBtn";
import Pad from "./inputs/Pop";
import useDocContext from "./useDocContext";
import { newDate } from "../utils/useDuration";
type Notice = typeof noticeInit;
const noticeInit = {
  history: [""],
  outside: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  inside: [0, 0, 0, 0, 0, 0, 0],
  items: [0, 0, 0, 0, 0, 0],
  special: [0, 0, 0, 0, 0],
  other: ["", "", "", ""],
  fuel: [0],
  delivery: ["", ""],
};
const Doc2Notice = () => {
  const { imm, disabled } = useDocContext();
  const notice = <K extends keyof Notice>(key: K, at: number, value?: Notice[K][number]) => {
    let obj = jsonParse(imm[0].notice) as Notice;
    if (!obj) obj = noticeInit;
    if (!obj[key]) obj = { ...obj, [key]: [] };
    if (value !== undefined)
      imm[1]((d) => {
        d.notice = JSON.stringify(
          produce(obj, (n) => {
            n[key][at] = value;
          })
        );
      });
    return obj[key][at];
  };
  const [yyyy, mm, dd] = (notice("delivery", 0) as string).split("-");
  const check = <K extends keyof Notice>(key: K, at: number, mark?: string) => (
    <DocTd width="3%">
      <DocTextBtn children={notice(key, at) ? mark || "✕" : "　"} onClick={() => notice(key, at, notice(key, at) ? 0 : 1)} />
    </DocTd>
  );
  const text = <K extends keyof Notice>(key: K, at: number) => <DocInput value={notice(key, at)} onChange={(e) => notice(key, at, e.target.value)} />;
  return (
    <>
      <DocTable noBorderTop heading="Maintenance 维修历史">
        <tr>
          <DocTdLabel width="40%" colSpan={4} en='Outside Confirmation(abnormal mark"x")' br zh="外观确认（不正常划“×”）" style={{ backgroundColor: "#ddd" }} />
          <DocTdLabel width="40%" colSpan={4} en='Inside Confirmation (abnormal mark"x")' br zh="室内确认 (不正常划“×”)" style={{ backgroundColor: "#ddd" }} />
          <DocTdLabel width="20%" colSpan={2} en="Fuel Volume Confirmation" br zh="油量确认" style={{ backgroundColor: "#ddd" }} />
        </tr>
        <tr>
          <DocTd width={"17%"} children="Front screen 前挡玻璃" />
          {check("outside", 0)}
          <DocTd width={"17%"} children="Grill 中网" />
          {check("outside", 1)}
          <DocTd width={"17%"} children="Ceiling顶棚" />
          {check("inside", 0)}
          <DocTd width={"17%"} children="Air-conditioning空调" />
          {check("inside", 1)}
          <td rowSpan={4} colSpan={2} style={{ padding: "0 5px" }}>
            <Slider
              sx={{
                color: "#444",
                "& .MuiSlider-thumb::before": {
                  boxShadow: "none",
                  "&:focus, &:hover, &.Mui-active": {
                    boxShadow: "none",
                  },
                },
                "& .MuiSlider-thumb": {
                  color: "#000",
                  height: "20px",
                  width: "2px",
                  // marginTop: "-px",
                  borderRadius: "1px",
                  "&:focus, &:hover, &.Mui-active": {
                    boxShadow: "none",
                  },
                },
                "& .MuiSlider-track": {
                  border: "none",
                },
                "& .MuiSlider-mark": {
                  backgroundColor: "#bfbfbf",
                  height: 12,
                  marginTop: "4px",
                  "&.MuiSlider-markActive": {
                    opacity: 1,
                    backgroundColor: "currentColor",
                  },
                },
              }}
              valueLabelDisplay="auto"
              min={-3}
              max={101}
              marks={[
                {
                  value: 0,
                  label: <strong style={{ paddingLeft: "5px" }}>E</strong>,
                },
                { value: 25 },
                { value: 50, label: "1/2" },
                { value: 75 },
                {
                  value: 100,
                  label: <strong style={{ paddingRight: "5px" }}>F</strong>,
                },
              ]}
              value={notice("fuel", 0) as number}
              onChange={(e, v) => notice("fuel", 0, v as number)}
            />
          </td>
        </tr>
        <tr>
          <DocTd children="Rear screen 后挡玻璃" />
          {check("outside", 2)}
          <DocTd children="Front bumper前保险杆" />
          {check("outside", 3)}
          <DocTd children="Seats座椅" />
          {check("inside", 2)}
          <DocTd children="Glass Lifters玻璃升降器" />
          {check("inside", 3)}
        </tr>
        <tr>
          <DocTd children="Window glass车窗玻璃" />
          {check("outside", 4)}
          <DocTd children="Rear bumper后保险杆" />
          {check("outside", 5)}
          <DocTd children="Audio音响" />
          {check("inside", 4)}
          <DocTd children="Rear view mirror后视镜" />
          {check("inside", 5)}
        </tr>
        <tr>
          <DocTd children="Headlamps前大灯" />
          {check("outside", 6)}
          <DocTd children="Doors车门" />
          {check("outside", 7)}
          <DocTd children="Seat belt安全带" />
          {check("inside", 6)}
          <DocTd colSpan={2}>
            <span style={{ whiteSpace: "nowrap" }}> Others其它</span>
            {text("other", 0)}
          </DocTd>
        </tr>
        <tr>
          <DocTd children="Tail Lamps后尾灯" />
          {check("outside", 8)}
          <DocTd children="Door handles门把手" />
          {check("outside", 9)}
          <DocTd colSpan={6} children='Confirmation of items (abnormal mark"x") 物品确认 (不正常划“×”)' style={{ backgroundColor: "#ddd" }} />
        </tr>
        <tr>
          <DocTd children="Fog lamps雾灯" />
          {check("outside", 10)}
          <DocTd children="Fenders叶子板" />
          {check("outside", 11)}
          <DocTd children="Toolkit工具包" />
          {check("items", 0)}
          <DocTd children="Spare Tire备胎" />
          {check("items", 1)}
          <DocTd width={"17%"} children="Reflective vests反光衣" />
          {check("items", 2)}
        </tr>
        <tr>
          <DocTd children="Side mirrors后视镜" />
          {check("outside", 12)}
          <DocTd children="Mudguards挡泥板" />
          {check("outside", 13)}
          <DocTd children="Jack千斤顶" />
          {check("items", 3)}
          <DocTd children="Extinguisher灭火器" />
          {check("items", 4)}
          <DocTd children="Triangle三角牌" />
          {check("items", 5)}
        </tr>
        <tr>
          <DocTd children="Wheel studs轮毂螺丝" />
          {check("outside", 14)}
          <DocTd children="Others其他" />
          <td colSpan={7}> {text("other", 1)}</td>
        </tr>
        <tr>
          <DocTd colSpan={10} children='Special Notes (if it is mark "√") 特别说明(是则划“√”)' style={{ backgroundColor: "#ddd" }} />
        </tr>
        <tr>
          <DocTd colSpan={4} children="Do you need to return the old parts? 旧件是否需要交还" />
          {check("special", 0, "✓")}
          <DocTd colSpan={4} children="Customers will wait at our place 客户在本店等候" />
          {check("special", 1, "✓")}
        </tr>
        <tr>
          <DocTd colSpan={4} children="Will you bring your valuables away? 贵重物品自行保管" />
          {check("special", 2, "✓")}
          <DocTd colSpan={4} children="Customers will leave our place 客户离开本店" />
          {check("special", 3, "✓")}
        </tr>
        <tr>
          <DocTd colSpan={4} children="Do you need car wash (charge)?是否需要洗车" />
          {check("special", 4, "✓")}
          <DocTd colSpan={5}>
            <span style={{ whiteSpace: "nowrap" }}> Others其它</span>
            {text("other", 3)}
          </DocTd>
        </tr>
      </DocTable>
      <DocTable heading="Reception Results 接待结果">
        <tr>
          <DocTd width="5%">1</DocTd>
          <DocTd width="70%">
            <div style={{ position: "relative" }}>
              <Pad>
                <TextField
                  type="date"
                  variant="standard"
                  label={"移交日期"}
                  autoFocus
                  sx={{ margin: 0.5 }}
                  value={notice("delivery", 0)}
                  onChange={(e) => notice("delivery", 0, e.target.value)}
                />
              </Pad>
              The vehicle has been delivered on _{dd}_D日/_{mm}_M月/__{yyyy}
              __Y年. 本车已移交车间。
            </div>
          </DocTd>
          <DocTd colSpan={2}>
            <span style={{ whiteSpace: "nowrap" }}> Mechanic维修技师:</span>
            {text("delivery", 1)}
          </DocTd>
        </tr>
        <tr>
          <DocTd width="5%">2</DocTd>
          <DocTd colSpan={2}>
            The vehicle left our workshop after checking and quotation, hasn't been repaired/serviced at our workshop.
            本车经检查报价后离开，并未在本店维修/保养。
          </DocTd>
          <DocTd width="8%">
            <DocTextBtn children={!notice("delivery", 0) ? "✓" : "　"} onClick={() => notice("delivery", 0, !notice("delivery", 0) ? newDate() : "")} />
          </DocTd>
        </tr>
      </DocTable>
    </>
  );
};

export default Doc2Notice;
