import { makeVar } from "@apollo/client";
import { Department, Title } from "./useEmployeeList";
import React from "react";
import useExcerpt from "./useExcerpt";

const dataSheetRowInit = {
  name: "",
  housing: true,
  transport: true,
  department: "office" as Department,
  title: "volunteer" as Title,
  // basic: 0,
  year: 0,
  talk: 0,
  over: 0,
  work: 0,
  full: false,
  absent: 0,
  advance: 0,
  deduct: 0,
};

export const excerptInit = {
  dataSheetKeys: ["name", "department", "title", "housing", "transport", "year", "talk", "over", "work", "full", "absent", "advance", "deduct"] as const,
  dataSheetRows: [dataSheetRowInit],
  setting: { housingScale: 0.3, transportFix: 153, seniorityYearScale: 0.05, fullAttendanceFix: 200, napasaScale: 0.05, nhiScale: 0.01 },
  basics: { volunteer: 0, apprentice: 1050, technician: 1278, senior: 2167.5, manager: 4000 },
  payrollKeys: [
    "sn.",
    "name",
    "Basic Salary",
    "Housing Allowance",
    "Transport Allowance",
    "Seniority Allowance",
    "Talk time Allowance",
    "Total Amount (ZMW)",
    "Overtime",
    "Work Bonus",
    "Full Attendance Bonus",
    "Absence",
    "Actual Amount (ZMW)",
    "NAPSA",
    "NHI",
    "PAYE",
    "Net Salary (ZMW)",
    "Advanced Payment", //
    "compo sition", //
    "Actual Amount (ZMW).", //
    "Confirmed by Signature",
  ] as const,
};

const varExcerpt = makeVar(excerptInit);
const varEdited = makeVar(false);

const genPayroll = (excerpt?: typeof excerptInit) => {
  if (!excerpt) return [];
  // console.log(excerptInit);

  const { housingScale, seniorityYearScale, transportFix, napasaScale, nhiScale } = excerpt.setting;
  const payeeCalc = (income: number) => income * 0;
  return excerpt.dataSheetRows.map((data) => {
    const basic = excerpt.basics[data.title] || 0;
    const housing = basic * housingScale * (data.housing === undefined ? 1 : data.housing ? 1 : 0);
    const transport = transportFix * (data.transport === undefined ? 1 : data.transport ? 1 : 0);
    const seniority = basic * data.year * seniorityYearScale;
    const salaryTotal = basic + housing + transport + seniority + data.talk * 1;
    const over = (basic / 200) * 2 * data.over;
    const work = data.work * 1;
    const full = data.full ? excerpt.setting.fullAttendanceFix : 0;
    const absence = -(basic / 25) * data.absent;
    const totalBonus = +over + work + full + absence;
    const actualAmount = +salaryTotal + totalBonus;
    const napasa = -actualAmount * napasaScale;
    const nhi = -actualAmount * nhiScale;
    const paye = -payeeCalc(actualAmount);
    const netSalary = actualAmount + napasa + nhi + paye;
    const balance = netSalary + data.advance * 1 + data.deduct * 1;
    return {
      "sn.": 0,
      name: data.name,
      "Basic Salary": basic,
      "Housing Allowance": housing,
      "Transport Allowance": transport,
      "Seniority Allowance": seniority,
      "Talk time Allowance": data.talk,
      "Total Amount (ZMW)": salaryTotal,
      Overtime: over,
      "Work Bonus": work,
      "Full Attendance Bonus": full,
      Absence: absence,
      "Actual Amount (ZMW)": actualAmount,
      NAPSA: napasa,
      NHI: nhi,
      PAYE: paye,
      "Net Salary (ZMW)": netSalary,
      "Advanced Payment": data.advance,
      "compo sition": data.deduct,
      "Actual Amount (ZMW).": balance,
      "Confirmed by Signature": "",
    };
  });
};

const usePayroll = (slug: string) => {
  const props = useExcerpt(varExcerpt, varEdited, `payroll-${slug}`);
  const payRollRows = React.useMemo(() => genPayroll(props.excerpt), [props]);
  const payRollSum = Object.fromEntries(
    excerptInit.payrollKeys.map((k) => [
      k,
      k === "name" ? "Summary" : k !== "sn." && k !== "Confirmed by Signature" ? payRollRows.reduce((p, c) => p + (c[k] * 1 || 0), 0) : "",
    ])
  );
  return { ...props, payRollRows, excerptInit, dataSheetRowInit, payRollSum };
};

export default usePayroll;
