import { gql, useQuery } from "@apollo/client";
import React from "react";
import { MaxSnQuery, MaxSnQueryVariables } from "../api/types/graphql";
const queryMaxSn = gql`
  query MaxSn {
    docs(first: 1, where: { orderby: { field: SLUG, order: DESC }, stati: [DRAFT, PRIVATE] }) {
      nodes {
        slug
      }
    }
  }
`;
const useApiMaxSlug = () => {
  const { data } = useQuery<MaxSnQuery, MaxSnQueryVariables>(queryMaxSn);

  const maxSlug = data?.docs?.nodes[0]?.slug || undefined;
  return { maxSlug };
};

export default useApiMaxSlug;
