import { styled } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import usePayroll from "../../hooks/usePayroll";
import PureTable from "../../components/data-display/PureTable";
import { formatAmount } from "../../utils/format";
import PayrollTable from "../../components/data-display/PayrollTable";
import SalarySlip from "../../components/data-display/SalarySlip";
import Layout from "../../layout/Layout";
const Portrait = styled("div")`
  padding: 1rem;
  @page {
    size: A4 portrait;
  }
`;
const PagePayrollSlips = () => {
  const { slug } = useParams();
  const { excerpt, payRollRows: payRollRows } = usePayroll(slug as string);
  if (!slug) return null;
  return (
    <Layout title={`Payroll Slips ${slug}`}>
    <Portrait>
      <div className="no-print">
        PagePayrollSlips for {slug} , <Link to={`/payroll/${slug}`}>Payroll</Link>
      </div>
      <div>
        {payRollRows.map((row) => {
          return (
            <SalarySlip
              fullname={row.name}
              slug={slug || ""}
              line1Keys={[
                "Basic Salary",
                "Housing Allowance",
                "Transport Allowance",
                "Seniority Allowance",
                "Talk time Allowance",
                "Total Amount (ZMW)",
                "Overtime",
                "Work Bonus",
                "Full Attendance Bonus",
                "Absence",
                "Actual Amount (ZMW)",
              ]}
              line2Keys={["NAPSA", "NHI", "PAYE", "Net Salary (ZMW)", "Advanced Payment", "compo sition", "Actual Amount (ZMW)."]}
              row={row}
            />
          );
        })}
      </div>
    </Portrait></Layout>
  );
};

export default PagePayrollSlips;
