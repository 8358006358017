import { serializeSn, serializeTitleNode as sr } from "../../utils/format";
import { newDate } from "../../utils/useDuration";

export type Type = (typeof types)[number];
export const types = ["r", "c", "q", "j", "m", "b", "i"] as const;

export type TypeEn = (typeof types_en)[number];
export const types_en = ["reception", "checkcard", "quotation", "jobcard", "matcard", "bill", "invoice"] as const;

export type TypeZh = (typeof types_zh)[number];
export const types_zh = ["接车单", "检查单", "报价单", "工单", "材料单", "结算单", "发票"] as const;

export type Team = (typeof teams)[number];
export const teams = ["UNCLEAR", "OFFICE", "PAN", "HONG", "GANG", "JIAN", "CLAY"] as const;

export type TeamZh = (typeof teams)[number];
export const teams_zh = ["X", "办", "攀", "洪", "刚", "健", "卡"] as const;

export type Status = (typeof statuses)[number];
export const statuses = ["draft", "publish", "template"] as const;

export type Author = (typeof authors)[number];
export const authors = ["guest", "bell", "hong", "wei", "ben", "memo", "max", "min"];

// slug sample 'q-230001-20230621-80001-hilux1gd-bal2288-2580-1-2-6'

export type Title = typeof titleInit;
export const titleInit = { type: "" as Type, sn: 0, date: "", plate: "", model: "", vip: "", teamId: 0, labor: 0, material: 0, tax: 0, cost: 0 };

export const titleParse = (title?: string | null): Title => {
  const [type, sn, date, plate, model, vip, teamId, labor, material, tax, cost] = (title || "").split("~");
  return {
    type: type as Type,
    sn: +sn,
    date,
    plate,
    model,
    vip,
    teamId: +teamId,
    labor: +labor,
    material: +material,
    tax: +tax,
    cost: +cost,
  };
};

export const titleSerialize = (slugObject?: Partial<Title>): string => {
  const { type, sn, date, plate, model, vip, teamId, labor, material, tax, cost } = slugObject || {};
  return `${sr(type)}~${serializeSn(sn)}~${sr(date)}~${sr(plate)}~${sr(model)}~${sr(vip)}~${sr(teamId)}~${sr(labor)}~${sr(material)}~${sr(tax)}~${sr(cost)}`;
};
