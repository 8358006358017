import { Delete, North, NorthEast, NorthWest, South, SouthEast, SouthWest } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React, { ReactNode } from "react";
import DocIconBtn from "./DocIconBtn";
import Pad, { PopProps } from "./Pop";
type Btn = "inUp" | "inDn" | "mvUp" | "mvDn" | "del";

type P = PopProps & {
  clicked: (btn: Btn) => void;
  disable?: { [k in Btn]?: boolean };
  more?: ReactNode;
  disabled?: boolean;
};
const RowMenu = ({ clicked, disable, more, children, disabled, ...rest }: P) => {
  if (disabled) return null;
  const left = (rest.sx as any)?.left;
  return (
    <Pad clickClose {...rest}>
      {more}
      <Stack direction={left ? "row" : "row-reverse"}>
        <DocIconBtn disabled />
        <DocIconBtn MuiIcon={Delete} disabled={disable?.del} onClick={() => clicked("del")} color="error" />
        <DocIconBtn MuiIcon={North} disabled={disable?.mvUp} onClick={() => clicked("mvUp")} color="success" />
        <DocIconBtn MuiIcon={South} disabled={disable?.mvDn} onClick={() => clicked("mvDn")} color="success" />
        <DocIconBtn MuiIcon={left ? NorthEast : NorthWest} disabled={disable?.inUp} onClick={() => clicked("inUp")} />
        <DocIconBtn MuiIcon={left ? SouthEast : SouthWest} disabled={disable?.inDn} onClick={() => clicked("inDn")} />
        {children}
      </Stack>
    </Pad>
  );
};

export default RowMenu;
